import React, { useState, useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBDataTableV5 } from 'mdbreact';
import firebase from "firebase";
import defaultImage from '../../default.png'
import loadingImage from '../../loading.gif'
import Switch from "react-switch";
import { Link } from "react-router-dom";
import * as Constants from '../StateManager/Constants'
import Modal from "react-modal";
import OrderDetails from './OrderDetails';
const modal_root = document.getElementById("modal_root");
Modal.setAppElement(modal_root);

function Orders() {
    const dbpathcat = "customerapp/categories";
    const dbpathUnits = "customerapp/units";
    const dbpathOrders = "customerapp/orders";
    const dbpathUsers = "customerapp/users";
    const dbpathDeliveryBoy = "customerapp/delivery/users";
    const [orderItems, setOrderItems] = useState([])
    const [allUnits, setAllUnits] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allDeliveryUsers, setAllDeliveryUsers] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [pendingOnly, setPendingOnly] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState({});
    const [OrdersDatatable, setOrdersDatatable] = useState({
        columns: [
            {
                label: 'Image',
                field: 'image',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Category',
                },
            },
            {
                label: 'Order ID',
                field: 'orderid',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Category',
                },
            },
            {
                label: 'Time Stamp',
                field: 'timestamp',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: 'Price',
                field: 'orderAmount',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Description',
                },
            },
            {
                label: 'Status',
                field: 'status',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Price',
                },
            },
            {
                label: 'Pending',
                field: 'pending',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Price',
                },
            },
            {
                label: 'Action',
                field: 'action',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action',
                },
            },
        ], rows: []
    });

    var completeOrderJson = {};

    useEffect(() => {
        if(!pendingOnly)
        {fetchOrderItems()
        fetchUnits()}
        else {
            console.log(OrdersDatatable)
            setOrdersDatatable(old=>({...old,rows:old.rows.filter(e=>e.pending&&e.pending>0)}));
        }
    }, [pendingOnly])
    useEffect(() => {
        if(orderItems){
            console.log(orderItems,"loded orderItems");
        }
    }, [orderItems])
    const getImages = (imgObj) => {
        let obj = [];
        imgObj.forEach(element => {
            obj.push(element)
        });
        return obj ? obj.sort((a, b) => b.isDefault - a.isDefault) : undefined;
    }
    const getTimeStr = (timeStr) => {
        var datestring = "";
        if (timeStr) {
            var dt = new Date(timeStr);
            datestring = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" +
                dt.getFullYear() + " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2);
        }
        return datestring;
    }
    const getRow = (Orderdetail,oindex) => {
        console.log(Orderdetail,1001)
        let image = getImages(Orderdetail.products[0].images)[0];
        let obj = {
            orderid: Orderdetail.orderid,
            image: (<img style={{ width: "95px" }} src={image.url} alt="" />),
            timestamp: getTimeStr(Orderdetail.createdAt),
            pending:Orderdetail.pending?Orderdetail.pending:0,
            orderAmount: "₹" + Orderdetail.totalAmount + "(" + Orderdetail.products.length + " items)",
            status: Orderdetail.status,
            action: (<button type="button" onClick={() => {
                //  selectedOrderDetailIndex.userid = Orderdetail.userid;
                //  selectedOrderDetailIndex.orderid = Orderdetail.orderid;
                console.log("getRow ",Constants.orderList.orders);
                let userdetails = { userid: Orderdetail.userid, orderid: Orderdetail.orderid };
                setSelectedOrderDetails(Constants.orderList.orders[userdetails.userid][userdetails.orderid])
                Constants.updatedOrder.pos = oindex;
                //  console.log(selectedOrderDetailIndex);
                //  console.log();
                setTimeout(() => {
                    console.log(selectedOrderDetails);
                    setModalOpen(true)
                }, 300);
            }} className="btn btn-rounded btn-sm  btn-success">View</button>),
        };
        return obj;
    }
    const fetchUnits = async () => {
        firebase
            .database()
            .ref(dbpathUnits)
            .once(`value`, (snap) => {
                const data = snap.val();
                if (data) {
                    console.log("Unites ", data);
                    setAllUnits(data);
                }
            });

        firebase.database()
            .ref(dbpathUsers)
            .once('value')
            .then(snapshot => {
                let data = snapshot.val();
                if (data) {
                    setAllUsers(data)
                }
            });
        firebase.database()
            .ref(dbpathDeliveryBoy)
            .once('value')
            .then(snapshot => {
                let data = snapshot.val();
                if (data) {
                    let dbs = [];
                    Object.keys(data).map((dbid)=>{
                        if(data[dbid].isProfileComplete && !data[dbid].isBlocked){
                            dbs.push({...data[dbid],fbid:dbid})
                        }
                    });
                    setAllDeliveryUsers(dbs)
                }
            });
    }

    const fetchOrderItems = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathOrders)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data) {
                    if (data) {
                        let orderz = []
                        Constants.orderList.orders = data;
                        Object.keys(data).map((userid) => {
                            Object.keys(data[userid]).map((oid) => {
                                    orderz.push(data[userid][oid])
                            })
                        })
                        if (orderz.length > 0) {
                            console.log("assigned ", orderz);
                            orderz.sort((a, b) => b.createdAt - a.createdAt)
                        }
                        let newArray = []
                        let oindex = 0;
                        orderz.forEach(element => {
                            newArray.push(getRow(element,oindex))
                            oindex++
                        });
                        console.log(newArray);
                        OrdersDatatable.rows = newArray;
                        setOrdersDatatable({ ...OrdersDatatable });
                        setOrderItems(data)
                    }
                }
            });



    }
    return (
        <>
            <button className="btn btn-success" onClick={()=>setPendingOnly(o=>!o)}>{pendingOnly?"Hide":"Show"} Pending Only</button>
            <MDBDataTableV5
                scrollX hover entriesOptions={[5, 20, 25, 50, 100]}
                entries={20} pagesAmount={4} data={OrdersDatatable} />

            <Modal
                isOpen={modalOpen}
                onRequestClose={() => {
                     }}
                contentLabel="Example Modal"
            >
                <button style={{ zIndex: "999999999", position: 'absolute', right: '0' }}
                    type="button"
                    onClick={() => {
                        let odtls = Constants.updatedOrder.item;
                        console.log(odtls, 'xxxxxxxx');
                        if(Constants.updatedOrder.pos!=-1 && Object.keys(odtls).length>0){
                            let odIndx = Constants.updatedOrder.pos;
                            OrdersDatatable.rows[odIndx] = getRow(odtls,odIndx);
                            setOrdersDatatable({ ...OrdersDatatable });
                            Constants.orderList.orders[odtls.userid][odtls.orderid] = odtls;
                        }
                        setTimeout(() => {
                            setModalOpen(false)
                        }, 300);
                     }}
                    className="btn btn-rounded btn-sm  btn-danger float-right">Close</button>
                {
                    modalOpen ? <OrderDetails Users={allUsers} DeliveryBoy={allDeliveryUsers} Units={allUnits} OrderDetail={selectedOrderDetails} /> : <></>
                }
            </Modal>
        </>
    )
}

export default Orders

import React from "react";
import firebase from "firebase";
import "./helpers/firebase";
import Landing from "./components/Landing";
import Dashboard from "./Admin/Dashboard";

const SwitchAuth = ({ authState }) => {
  switch (authState) {
    case null:
      return <h3 className="subtitle is-2 px-2 py-2">Loading</h3>;
    case false:
      return <Landing />;
    case true:
      return <Dashboard />;
  }
};

export default () => {
  const [LoggedIn, _setLoggedIn] = React.useState(null);

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        localStorage.setItem("uid", user.uid);
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            localStorage.setItem("token", token);
            _setLoggedIn(true);
          });
      } else {
        _setLoggedIn(false);
      }
    });
  }, []);

  React.useEffect(() => {});
  return <SwitchAuth authState={LoggedIn} />;
};

import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import { store } from 'react-notifications-component';
import imageCompression from "browser-image-compression";
import defaultImage from '../../default.png'
import Switch from "react-switch";

const Banners = () => {

    const [categotyTitle, setCategotyTitle] = useState("")
    const [categoryImage, setCategoryImage] = useState(null)
    const [imageLink, setImageLink] = useState("")
    const [isImageUpdated, setIsImageUpdated] = useState(false)
    const [categoryID, setCategoryID] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [buttonName, setButtonName] = useState("Add")
    const [categories, setCategories] = useState([])
    const [hide, setHide] = useState(false)
    const [showinhome, setShowinhome] = useState(false)
    const [showinside, setShowinside] = useState(false)
    const dbpath = "customerapp/banners";
    const imagepath = "banners/images"
    let tableIndex = 0;
    const clearForm = () => {
        setImageLink("")
        setIsProcessing(false)
        setIsImageUpdated(false)
        setCategoryImage(null);
        setCategoryID(""); 
        setHide(false);
        setCategotyTitle("");
        setButtonName("Add");
    }

    const FilesUpload = async (event) => {
        const imageFile = event.target.files[0];
        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        try {
            const compressedFile = await imageCompression(imageFile, options);
            const url = URL.createObjectURL(compressedFile);
            setImageLink(url);
            setCategoryImage(compressedFile);
            setIsImageUpdated(true)
        } catch (error) {
            console.log(error);
        }
    };
    const saveToDb = (catid, imageUrl) => {
        let bannr = [...categories];
        let obj = { title: categotyTitle, url: imageUrl,hide }
        if(categoryID!=""){
            bannr[categoryID] = {...obj}
        }else{
            bannr.push(obj);
        }
        console.log(bannr);
        firebase
            .database()
            .ref(`${dbpath}`)
            .set(bannr)
            .then(() => {
                clearForm();
                store.addNotification({
                    title: "Notification",
                    message: "Changes saved",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
                fetchCategories();
            });

    }
    const addCategory = async () => {
        if (categotyTitle.trim().length > 0) {
            if (imageLink == "") {
                store.addNotification({
                    title: "Notification",
                    message: "Please add category image",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
                return;
            }

            setIsProcessing(true)
            var dt = new Date();
            let unique = Math.random().toString().substring(4, 6);
            let catid = unique + dt.getTime();
            let _categoryID = catid;
            if (categoryID != "") {
                _categoryID = categoryID;
            }
            if (isImageUpdated) {
                await firebase
                    .storage()
                    .ref(`${imagepath}/${_categoryID}`)
                    .put(categoryImage)
                    .then(() => {
                        firebase
                            .storage()
                            .ref(`${imagepath}/${_categoryID}`)
                            .getDownloadURL()
                            .then((url) => {
                                saveToDb(_categoryID, url)
                            })
                            .catch((e) => console.log(e));
                    })
                    .catch((e) => console.log(e));
            } else {
                saveToDb(_categoryID, imageLink)
            }

        } else {
            //empty form
            store.addNotification({
                title: "Notification",
                message: "Please enter banner title",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }


    }

    const fetchCategories = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpath)
            .once(`value`, (snap) => {
                const data = snap.val();
                tableIndex = 0;
                // console.log(data);
                if (data)
                    setCategories(data)
            });
    }
    const setValuesToForm = (key, category) => {
        setCategoryID(key);
        setCategotyTitle(category.title||"");
        setImageLink(category.url||"");
        setShowinside(category.showinside||false);
        setShowinhome(category.showinhome||false);
        setHide(category.hide||false);
        setIsImageUpdated(false);
        setButtonName("Update");
        window.scrollTo(0, 0)
    }
    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className='m-1 pt-5'>
                            <input required className='form-control' type={"text"} placeholder='Banner Title' value={categotyTitle} onChange={(e) => setCategotyTitle(e.target.value)} />
                        </div>
                        <div className='m-1'>
                            <img onClick={() => {
                                document.getElementById("catimage").click();
                            }} alt="" style={{ width: 200, height: 100 }} className="mt-3" src={imageLink || defaultImage} />
                            <br />
                            <label htmlFor="catimage">Set Banner Image</label>
                            <input className='d-none' id={"catimage"} type={"file"} onChange={(event) => FilesUpload(event)} />
                        </div>
                        <div className='m-1'>
                            <div>
                                <label>Hide the Banner?</label><br />
                                <Switch onChange={() => { setHide(!hide) }}
                                    checked={hide}
                                />
                            </div>
                        </div> 
                        <div>
                            <button disabled={isProcessing} type='submit' onClick={() => { addCategory() }} className='btn btn-info  btn-sm'>{buttonName}</button>
                            <button disabled={isProcessing} type='reset' onClick={() => { clearForm() }} className='btn btn-danger  btn-sm'>Clear</button>
                        </div>
                    </form>
                </div>
                <div className="col-sm" style={{ overflow: 'hidden auto', height: '90vh' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Image</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                categories.map((element,index) => {
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{++tableIndex}</th>
                                            <td>{index}{element.title}</td>
                                            <td>
                                                <img alt="" style={{ width: 200, height: 100 }} className="iamge-fluid" src={element.url || defaultImage} /></td>
                                            <td >
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                                    <div style={{ cursor: "pointer" }} onClick={() => { setValuesToForm(index, element) }}><ion-icon name="pencil-sharp" style={{ marginRight: 5, cursor: "pointer" }} className="icon"></ion-icon></div>
                                                    {/* <div style={{ cursor: "pointer" }} ><ion-icon name="trash" style={{ marginRight: 5, cursor: "pointer", color: "#f00" }} className="icon imr3"></ion-icon></div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Banners

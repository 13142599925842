import React from "react";
import styled from "styled-components";
import firebase from "firebase";
import ReactLoading from "react-loading";

const Container = styled.div`
  input {
    width: 50%;
    margin-bottom: 10px;
  }
`;

const SetDeliveryRate = () => {
  const [deliveryRate, setDelieveryRate] = React.useState({
    under1000: null,
    "1to2": "",
    "2to3": "",
    "3to4": "",
    "4andUp": "",
  });

  React.useEffect(() => {
    firebase
      .database()
      .ref(`delieveryRate`)
      .once("value", (snap) => {
        setTimeout(() => {
          setDelieveryRate(snap.val());
        }, 1000);
      });
  }, []);

  const PriceSet = () => {
    try {
      let bool = true;
      Object.values(deliveryRate).map((item) => {
        const Conditon =
          typeof parseInt(item) === "number" && parseInt(item) > 0;
        if (!Conditon) {
          bool = false;
        }
      });
      if (bool) {
        firebase
          .database()
          .ref(`delieveryRate`)
          .set(deliveryRate)
          .then(() => {
            alert("Delivery Rate Set !");
          });
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Container className="py-3">
      <h3 className="title is-3">Delievery Rate</h3>
      {deliveryRate.under1000 === null ? (
        <ReactLoading type={"spin"} color="#000" />
      ) : (
        <div>
          <h3>Orders under 1000</h3>
          <input
            className="input"
            value={deliveryRate.under1000}
            onChange={(e) => {
              setDelieveryRate({ ...deliveryRate, under1000: e.target.value });
            }}
          />
          <h3>Orders between 1000 to 2000</h3>
          <input
            className="input"
            value={deliveryRate["1to2"]}
            onChange={(e) => {
              setDelieveryRate({ ...deliveryRate, "1to2": e.target.value });
            }}
          />
          <h3>Orders between 2000 to 3000</h3>
          <input
            className="input"
            value={deliveryRate["2to3"]}
            onChange={(e) => {
              setDelieveryRate({ ...deliveryRate, "2to3": e.target.value });
            }}
          />
          <h3>Orders between 3000 to 4000</h3>
          <input
            className="input"
            value={deliveryRate["3to4"]}
            onChange={(e) => {
              setDelieveryRate({ ...deliveryRate, "3to4": e.target.value });
            }}
          />
          <h3>Orders between 4000 and up</h3>
          <input
            className="input"
            value={deliveryRate["3to4"]}
            onChange={(e) => {
              setDelieveryRate({ ...deliveryRate, "3to4": e.target.value });
            }}
          />
          <br />
          <button onClick={PriceSet} className="button is-link">
            Set Rate
          </button>
        </div>
      )}
    </Container>
  );
};
export default SetDeliveryRate;

import React from "react";
import styled from "styled-components";
import firebase from "firebase";
import imageCompression from "browser-image-compression";

const Container = styled.div`
  img {
    border-radius: 20px;
  }
`;

const CreateCategory = ({ FetchCategory }) => {
  const [ImageLink, _setImageLink] = React.useState("");
  const [file, _setFile] = React.useState(null);
  const [categoryName, _setCategoryName] = React.useState("");
  const [loading, _setLoading] = React.useState(false);

  const FilesUpload = async (event) => {
    const imageFile = event.target.files[0];
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      const url = URL.createObjectURL(compressedFile); // write your own logic
      _setImageLink(url);
      _setFile(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const Upload = async () => {
    if (categoryName === "") {
      return alert("Please provide a category name");
    }
    _setLoading(true);
    await firebase
      .storage()
      .ref(`categories/${categoryName}/Image`)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(`categories/${categoryName}/Image`)
          .getDownloadURL()
          .then((url) => {
            firebase
              .database()
              .ref(`categories/${categoryName}`)
              .set({ url: url })
              .then(() => {
                _setFile(null);
                _setImageLink("");
                _setCategoryName("");
                _setLoading(false);
                FetchCategory();
              });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  return (
    <Container className="columns is-multiline mx-2">
      <div className="column is-12">
        <h3 className="subtitle has-text-black is-4">Create Category</h3>
      </div>
      <div className="column is-12">
        <input
          className="input"
          value={categoryName}
          onChange={(e) => _setCategoryName(e.target.value)}
          placeholder="Category Name"
        />
        {ImageLink === "" ? (
          <>
            {" "}
            <input
              type="file"
              style={{ display: "none" }}
              id="image"
              onChange={(event) => FilesUpload(event)}
              name="img"
              accept="image/*"
            />
            <button
              onClick={() => {
                document.getElementById("image").click();
              }}
              className="button is-link mt-3"
            >
              Upload Image
            </button>
          </>
        ) : (
          <>
            <img alt="" className="mt-3" src={ImageLink} />
            <button
              onClick={() => {
                _setImageLink("");
                _setFile(null);
              }}
              style={{ width: "100%" }}
              className="button is-dark"
            >
              Cancel
            </button>
            <button
              onClick={Upload}
              style={{ width: "100%" }}
              className="button is-link mt-2 mb-6"
            >
              Submit
            </button>
          </>
        )}
        {loading && (
          <progress className="progress is-small is-primary" max="100">
            15%
          </progress>
        )}
      </div>
    </Container>
  );
};
export default CreateCategory;

import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import { store } from 'react-notifications-component';
import imageCompression from "browser-image-compression";
import defaultImage from '../../default.png'
import Switch from "react-switch";

const Units = () => {
    const [unitTitle, setUnitTitle] = useState("");
    const [unitID, setUnitID] = useState("")
    const [buttonName, setButtonName] = useState("Add");
    const [units, setUnits] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)
    const dbpathUnits = "customerapp/units";
    let tableIndex = 0;
    const fetchUnits = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathUnits)
            .once(`value`, (snap) => {
                const data = snap.val();
                tableIndex = 0;
                // console.log(data);
                if (data)
                    setUnits(data)
            });
    }
    const addUnit = () => {
        if (unitTitle.trim().length === 0) {
            showMessage("Unit title is required", "danger");
            return;
        }
        setIsProcessing(true);
        var dt = new Date();
        let unique = Math.random().toString().substring(4, 6);
        let uniteID = unique + dt.getTime();
        let untID = uniteID;

        if (unitID != "") {
            untID = unitID;
        }
        let obj = {
            title: unitTitle,
            createdOn: dt.getTime()
        };
        console.log(obj);
        firebase
            .database()
            .ref(`${dbpathUnits}/${untID}`)
            .update(obj)
            .then(() => {
                clearForm();
                showMessage("Changes Saved")
                fetchUnits();
            }).catch((e) => {
                clearForm();
                showMessage("Error, Failed to save", "danger")
                fetchUnits();
            });;
    }
    const showMessage = (message, type = "success") => {
        store.addNotification({
            title: "Notification",
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    }
    const clearForm = () => {
        setUnitID("");
        setUnitTitle("");
        setButtonName("Add");
        setIsProcessing(false);
        window.scrollTo(0, 0)
    }
    const setValuesToForm = (key, unit) => {
        if (!isProcessing) {
            setUnitID(key);
            setUnitTitle(unit.title);
            setButtonName("Update");
            window.scrollTo(0, 0)
        }
    }
    useEffect(() => {
        fetchUnits()
    }, [])
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className='m-1 pt-5'>
                            <input required className='form-control' type={"text"} placeholder='Unit Title' value={unitTitle} onChange={(e) => setUnitTitle(e.target.value)} />
                        </div>
                        <div>
                            <button disabled={isProcessing} type='submit' onClick={() => { addUnit() }} className='btn btn-info  btn-sm'>{buttonName}</button>
                            <button disabled={isProcessing} type='reset' onClick={() => { clearForm() }} className='btn btn-danger  btn-sm'>Clear</button>
                        </div>
                    </form>
                </div>
                <div className="col-sm" style={{ overflow: 'hidden auto', height: '90vh' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(units).map(key => {
                                    return (
                                        <tr key={key}>
                                            <th scope="row">{++tableIndex}</th>
                                            <td>{units[key].title}</td>
                                            <td >
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                                    <div style={{ cursor: "pointer" }} onClick={() => { setValuesToForm(key, units[key]) }}><ion-icon name="pencil-sharp" style={{ marginRight: 5, cursor: "pointer" }} className="icon"></ion-icon></div>
                                                    {/* <div style={{ cursor: "pointer" }} ><ion-icon name="trash" style={{ marginRight: 5, cursor: "pointer", color: "#f00" }} className="icon imr3"></ion-icon></div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Units

import firebase from "firebase";

firebase.initializeApp({
  apiKey: "AIzaSyDuXOGANdHY4QHl-0ChxAuAFouzGZwiSqc",
  authDomain: "freshtables-5ca16.firebaseapp.com",
  databaseURL: "https://freshtables-5ca16-default-rtdb.firebaseio.com",
  projectId: "freshtables-5ca16",
  storageBucket: "freshtables-5ca16.appspot.com",
  messagingSenderId: "893322883008",
  appId: "1:893322883008:web:5250126232917adac06225",
  measurementId: "G-ZXJ33KW5TJ",
});

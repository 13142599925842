import React, { useEffect,useState } from 'react'
import Sidebar from './Sidebar'
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from "firebase";
import './Dashboard.scss'
const Dashboard = () => {
    const [b2bPrice, setB2BPrice] = useState(0)
    const [d2cPrice, setD2CPrice] = useState(0)
    const fetchPrices = async () => {
        await firebase
            .database()
            .ref("deliveryEarnings")
            .once(`value`, (snap) => {
                const data = snap.val();
                if (data) {
                    if(data.b2b) setB2BPrice(data.b2b)
                    if(data.d2c) setD2CPrice(data.d2c)
                }
            });
    }
    function updatePrices(){
        firebase
            .database()
            .ref("deliveryEarnings")
            .update({b2b:b2bPrice,d2c:d2cPrice}).then(()=>{
                alert(`Prices Updated! B2B -> ${b2bPrice} D2C -> ${d2cPrice}`)
        });
    }
    useEffect(() => {
        fetchPrices()
        // action on update of movies
    }, [])
    return (
        <div>

            <h3>Dashboard</h3>
            <div className="prices">
                <div className="b2b">
                    <div className="title">B2B Delivery Charges</div>
                    <div className="price">
                        <input value={b2bPrice} onChange={e=>setB2BPrice(parseInt(e.target.value))} type="number" className='form-control' placeholder='Enter B2B delivery Charges' />
                    </div>
                </div>
                <div className="d2c">
                    <div className="title">D2C Delivery Charges</div>
                    <div className="price">
                        <input value={d2cPrice} onChange={e=>setD2CPrice(parseInt(e.target.value))} type="number" className='form-control' placeholder='Enter D2C delivery Charges' />
                    </div>
                </div>
                <button onClick={updatePrices} className="btn btn-success">Update</button>
            </div>
        </div>
    )
}

export default Dashboard

import * as React from "react";
import './Sidebar.scss'
import Image from "../freshtables.png";
import { Link } from "react-router-dom";
import firebase from "firebase";
import { RouteStore } from "./StateManager/StateManager.js";
import history from "./StateManager/history";
import * as Constants from './StateManager/Constants'
function Sidebar({ title, link, isLoggedin }) {
  const pageTitle = RouteStore.useState(s => s.pageTitle);
  const activeRoute = RouteStore.useState(s => s.activeRoute);
  const currentFun = RouteStore.useState(s => s.currentFun);

  function toggleHGM() {
    const sidebar = document.getElementById("sidebars");
    sidebar.classList.toggle("toggle");
  }
  function closeNav() {
    const sidebar = document.getElementById("sidebars");
    sidebar.classList.toggle("toggle");
  }
  return (
    !isLoggedin ? <></> :
      <div>
        <div className="navTitle">
          <div className="hamburger-menu" onClick={() => { toggleHGM() }}>
            <ion-icon name="menu-outline" id="hamburger-icon"></ion-icon>&nbsp;
          </div>
          <div className="addbuttonwrapper">
            <Link  onClick={()=>{
                //Constants.setPageTitle("/dashboard");
               }} style={{ textDecoration: 'none', color: '#fff' }} to={activeRoute || "#"}><h3 > &nbsp; {' ' + pageTitle || ''}</h3></Link>
            {currentFun && (<Link className="addicon" style={{color:"#fff"}} to={currentFun()}>
              <ion-icon name="add-outline" className="icon"></ion-icon>
           </Link>)}

          </div>

        </div>

        <div className="sidebar" id="sidebars">
          <img style={{ width: 100 }} alt="Freashtables" src={Image} />
          <div className="close-icon" id="close" onClick={() => { closeNav() }}>
            <ion-icon name="close-outline"></ion-icon>&nbsp;
          </div>

          <ul className="links" style={{overflow:"auto"}}>
            <li className={(activeRoute === "/") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/"); }} to={'/'}>
                <ion-icon name="grid-outline" className="icon imr3"></ion-icon>&nbsp; Dashboard
              </Link>
            </li><li className={(activeRoute === "/delivery-rates") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/delivery-rates"); }} to={'/delivery-rates'}>
                <ion-icon name="grid-outline" className="icon imr3"></ion-icon>&nbsp; Delivery Rates
              </Link>
            </li>
            <li className={(activeRoute === "/banners") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/banners"); }} to={'/banners'}>
                <ion-icon name="library-outline" className="icon imr3"></ion-icon>&nbsp; Banners
              </Link>
            </li>
            <li className={(activeRoute === "/push-notif") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/push-notif"); }} to={'/push-notif'}>
                <ion-icon name="library-outline" className="icon imr3"></ion-icon>&nbsp; Push Notifications
              </Link>
            </li>
            <li className={(activeRoute === "/categories-b2b") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/categories-b2b"); }} to={'/categories-b2b'}>
                <ion-icon name="library-outline" className="icon imr3"></ion-icon>&nbsp; Categories (B2B)
              </Link>
            </li>
            <li className={(activeRoute === "/categories") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/categories"); }} to={'/categories'}>
                <ion-icon name="library-outline" className="icon imr3"></ion-icon>&nbsp; Categories (D2C)
              </Link>
            </li>
            <li className={(activeRoute === "/products") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/products"); }} to={'/products'}>
                <ion-icon name="apps-outline" className="icon imr3"></ion-icon>&nbsp; Products
              </Link>
            </li>
            <li className={(activeRoute === "/upload-vegetables") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/upload-vegetables"); }} to={'/upload-vegetables'}>
                <ion-icon name="apps-outline" className="icon imr3"></ion-icon>&nbsp; Upload Vegetables
              </Link>
            </li>
            <li className={(activeRoute === "/coupons") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/coupons"); }} to={'/coupons'}>
                <ion-icon name="apps-outline" className="icon imr3"></ion-icon>&nbsp; Coupons
              </Link>
            </li>
            <li className={(activeRoute === "/units") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/units"); }} to={'/units'}>
                <ion-icon name="apps-outline" className="icon imr3"></ion-icon>&nbsp; Units
              </Link>
            </li>
            <li className={(activeRoute === "/orders-b2b") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/orders-b2b"); }} to={'/orders-b2b'}>
                <ion-icon name="list-outline" className="icon imr3"></ion-icon>&nbsp; Orders B2B
              </Link>
            </li>
            <li className={(activeRoute === "/orders") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/orders"); }} to={'/orders'}>
                <ion-icon name="list-outline" className="icon imr3"></ion-icon>&nbsp; Orders
              </Link>
            </li>
            <li className={(activeRoute === "/analytics") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/analytics"); }}  to={'/analytics'}>
                <ion-icon name="speedometer-outline" className="icon imr3"></ion-icon>&nbsp; Analytics
              </Link>
            </li>
            <li className={(activeRoute === "/users-b2b") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/users-b2b"); }} to={'/users-b2b'}>
                <ion-icon name="person-outline" className="icon imr3"></ion-icon>&nbsp; Users B2B
              </Link>
            </li>
            <li className={(activeRoute === "/deliveryBoys") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/deliveryBoys"); }} to={'/deliveryBoys'}>
                <ion-icon name="person-outline" className="icon imr3"></ion-icon>&nbsp; Delivery Users
              </Link>
            </li>
            <li className={(activeRoute === "/shippingTypes") ? "link active" : "link"}>
              <Link  onClick={()=>{
                Constants.setPageTitle("/shippingTypes"); }} to={'/shippingTypes'}>
                <ion-icon name="person-outline" className="icon imr3"></ion-icon>&nbsp; Shipping
              </Link>
            </li>
            <li className={(activeRoute === "/deliveryBoys") ? "link active" : "link"}>
              <Link  onClick={()=>{
                firebase
                .auth()
                .signOut()
                .then(() => {
                  localStorage.clear();
                  setTimeout(() => {
                    window.location.href="/";
                  }, 200);
                })
                 }} to={'/#'}>
                <ion-icon name="power-outline" className="icon imr3"></ion-icon>&nbsp; Logout
              </Link>
            </li>
            <li className={"link"} style={{height:"80px"}}>
            </li>
          </ul>
        </div>
      </div>
  )
}

export default Sidebar

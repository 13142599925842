import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import { store } from 'react-notifications-component';

const ShippingTypes = () => {
    const [shippingTitle, setShippingTitle] = useState("")
    const [shippingTime, setShippingTime] = useState("")
    const [shippingId, setShippingId] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [shippingTimes, setShippingTimes] = useState([])
    const [buttonName, setButtonName] = useState("Add")
    let tableIndex=0;
    const clearForm = () => {
        setShippingTitle("");
        setShippingTime("");
        setShippingId("");
        setButtonName("Add");
    }
    const addShippingTime = () => {
        if (shippingTitle.trim().length > 0 && shippingTime.trim().length > 0) {
            setIsProcessing(true)
            var dt = new Date();
            let stid = dt.getTime();
            let obj = { title: shippingTitle, time: shippingTime }
            if (shippingId == "") {
                firebase
                    .database()
                    .ref(`shippingTimes/${stid}`)
                    .update(obj)
                    .then(() => {
                        setIsProcessing(false)
                        clearForm();
                        store.addNotification({
                            title: "Notification",
                            message: "Changes saved",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        fetchShippingTimes();
                    });
            } else {
                //update
                firebase
                    .database()
                    .ref(`shippingTimes/${shippingId}`)
                    .update(obj)
                    .then(() => {
                        setIsProcessing(false)
                        clearForm();
                        store.addNotification({
                            title: "Notification",
                            message: "Changes updated",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                        fetchShippingTimes();
                    });
            }
        } else {
            //empty form
            store.addNotification({
                title: "Notification",
                message: "Fields are required",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 3000,
                    onScreen: true,
                },
            });
        }


    }
    const updateShippingTime = () => {

    }
    const fetchShippingTimes = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(`shippingTimes`)
            .once(`value`, (snap) => {
                const data = snap.val();
                tableIndex=0;
                setShippingTimes(data)
                // console.log(data);
            });
    }
    const setValuesToForm = (key,title,time) => {
        setShippingId(key);
        setShippingTitle(title);
        setShippingTime(time);
        setButtonName("Update");
    }
    useEffect(() => {
        fetchShippingTimes()
    }, [])
  
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className='m-1'>
                            <input required className='form-control' type={"text"} placeholder='Title' value={shippingTitle} onChange={(e) => setShippingTitle(e.target.value)} />
                        </div>
                        <div className='m-1'>
                            <input required className='form-control' type={"text"} placeholder='Value' value={shippingTime} onChange={(e) => setShippingTime(e.target.value)} />
                        </div>
                        <div>
                            <button disabled={isProcessing} type='submit' onClick={()=>{addShippingTime()}} className='btn btn-info  btn-sm'>{buttonName}</button>
                            <button disabled={isProcessing} type='reset' onClick={()=>{clearForm()}} className='btn btn-danger  btn-sm'>Clear</button>
                        </div>
                    </form>
                </div>
                <div className="col-sm">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Time</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(shippingTimes).map(key => {
                                    return <tr key={key}>
                                        <th scope="row">{++tableIndex}</th>
                                        <td>{shippingTimes[key].title}</td>
                                        <td>{shippingTimes[key].time}</td>
                                        <td style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                            <div style={{cursor:"pointer"}} onClick={()=>{setValuesToForm(key,shippingTimes[key].title,shippingTimes[key].time)}}><ion-icon name="pencil-sharp" style={{ marginRight: 5, cursor: "pointer" }} className="icon"></ion-icon></div>
                                            {/* <div style={{cursor:"pointer"}} ><ion-icon name="trash" style={{ marginRight: 5, cursor: "pointer", color: "#f00" }} className="icon imr3"></ion-icon></div> */}
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ShippingTypes

import React, {useState} from 'react';
import axios from 'axios';
import './styles.scss'
export default function PushNotifications(){
    const [title,setTitle]=useState("");
    const [message,setMessage]=useState("");
    function onSubmit(e){
        e.preventDefault();
        const key=process.env.REACT_APP_FCM_SERVER_KEY;
        if(!key) window.alert("No key found!")
        if(title===""||message==="")return;
        axios.post("https://fcm.googleapis.com/fcm/send",{
            notification:{
                title,
                body:message
            },
            "to":"/topics/all"
        },{
            headers:{

                "Authorization": `key=${key}`
            }
        }).then(res=>{
            console.log(res)
            window.alert("Notification sent successfully")

        }).catch(e=>{
            console.log(e)
            window.alert(e.message)
        })

    }

    return <div className="push-notif-main">
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <div className="form-label">Title</div>
                <input value={title} onChange={e=>setTitle(e.target.value)} className="form-control" placeholder="Enter Title"></input>
            </div>
            <div className="form-group">
                <div className="form-label">Description</div>
                <input value={message} onChange={e=>setMessage(e.target.value)} className="form-control" placeholder="Enter Title"></input>
            </div>
            <button disabled={title===""||message===""} className="btn btn-info" type="submit">Send</button>
        </form>
    </div>
}

import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import { store } from 'react-notifications-component';
import imageCompression from "browser-image-compression";
import defaultImage from '../../default.png'
import loadingImage from '../../loading.gif'
import Switch from "react-switch";

const AddProduct = () => {
    const dbpathcat = "customerapp/categories";
    const dbpathUnits = "customerapp/units";
    const dbpathcoupons = "customerapp/coupons";
    const dbpathprod = "customerapp/products";//products/catid/productid
    const imagepathprod = "products/images";//products/images/productid/all_images
    const [categories, setCategories] = useState([])
    const [productTitle, setProductTitle] = useState("")
    const [description, setDescription] = useState("")
    const [productImage, setProductImage] = useState([])
    const [isImageUpdated, setIsImageUpdated] = useState(false)
    const [productID, setProductID] = useState("")
    const [selectedCatId, setSelectedCatId] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [buttonName, setButtonName] = useState("Add")
    const [hideStatus, setHideStatus] = useState(false);
    const [coupons, setCoupons] = useState([])
    const [discountPackageID, setDiscountPackageID] = useState("")
    const [units, setUnits] = useState([])
    const [selectedUnit, setSelectedUnit] = useState("")
    const [maxQuantity, setMaxQuantity] = useState("")
    const [minQuantity, setMinQuantity] = useState("")
    const [productPrice, setProductPrice] = useState("")
    const [showPrice, setShowPrice] = useState("")
    const [stockSize, setStockSize] = useState("0")
    const [defaultImageIndex, setDefaultImageIndex] = useState(0)
    const [isImageLoading, setisImageLoading] = useState(false)
    const discountTypeArr = ["precentage", "flat"];
    const fetchCategories = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcat)
            .once(`value`, (snap) => {
                const data = snap.val();
                console.log(data);
                if (data)
                    setCategories(data)
            });
    }
    const fetchUnits = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathUnits)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data)
                    setUnits(data)
            });
    }
    const fetchCoupons = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcoupons)
            .once(`value`, (snap) => {
                const data = snap.val();
                console.log(data);
                if (data)
                    setCoupons(data)
            });
    }
    const clearForm = () => {
        setIsProcessing(false)
        setIsImageUpdated(false)
        setProductImage([]);
        setProductID("");
        setProductTitle("");
        setProductPrice("")
        setSelectedUnit("")
        setDiscountPackageID("")
        setSelectedCatId("")
        setHideStatus(false)
        setMaxQuantity("")
        setMinQuantity("");
        setDescription("")
        setShowPrice("")
        setStockSize(0);
        setButtonName("Add");
    }
    const setValuesToForm = (key, Product) => {
        setProductID(key)
        setIsProcessing(false)
        setIsImageUpdated(false)
        setProductImage(Product.images);
        setProductTitle(Product.title);
        setProductPrice(Product.amount)
        setSelectedUnit(Product.unitid)
        setDiscountPackageID(Product.couponid)
        setSelectedCatId(Product.catid)
        setHideStatus(Product.hide)
        setShowPrice(Product.showPrice)
        setMaxQuantity(Product.maxQuantity)
        setMinQuantity(Product.minQuantity);
        setStockSize(Product.stockSize)
        setDescription(Product.description)
        setButtonName("Update");
        window.scrollTo(0, 0)
    }
    const showMessage = (message, type = "success") => {
        store.addNotification({
            title: "Notification",
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    }
    const uploadToFirebase = async (index, _productID, productImages) => {
        let localImgObj = [];
        productImages.forEach(element => {
            localImgObj.push(element)
        });
        console.log("localImgObj ", localImgObj);
        console.log("call ", index);
        if (index >= productImage.length) {
            console.log("stop length = ", index);
            saveToDb(_productID, productImages);
            return;
        }
        if (index < productImage.length) {
            let unique = Math.random().toString().substring(4, 8);
            let filePath = `${imagepathprod}/${_productID}/${index + unique}`;
            await firebase
                .storage()
                .ref(filePath)
                .put(productImage[index].compressedFile)
                .then(() => {
                    firebase
                        .storage()
                        .ref(filePath)
                        .getDownloadURL()
                        .then((url) => {
                            console.log("success");
                            localImgObj.push({ fullPath: filePath, url: url, isDefault: defaultImageIndex == index })
                            var ind = index + 1;
                            uploadToFirebase(ind, _productID, localImgObj);
                        })
                        .catch((e) => {
                            console.log(e);
                            var ind = index + 1;
                            uploadToFirebase(ind, _productID, localImgObj);
                        });
                })
                .catch((e) => {
                    console.log(e);
                    var ind = index + 1;
                    uploadToFirebase(ind, _productID, localImgObj);
                });
        }

    }
    const addProduct = async () => {
        if (selectedCatId.trim().length === 0) {
            showMessage("Please select category first", "danger");
            return;
        }
        if (productTitle.trim().length === 0) {
            showMessage("Please enter product title", "danger");
            return;
        }
        if (productImage.length === 0) {
            showMessage("Please select product image", "danger");
            return;
        }
        if (productPrice.toString().trim().length === 0) {
            showMessage("Please enter product price", "danger");
            return;
        }
        if (selectedUnit.toString().trim().length === 0) {
            showMessage("Select quantity unit", "danger");
            return;
        }
        if (maxQuantity.toString().trim().length === 0) {
            showMessage("Please set maximum selling quantity", "danger");
            return;
        }
        if (minQuantity.toString().trim().length === 0) {
            showMessage("Please set minimum selling quantity", "danger");
            return;
        }
        if (stockSize.toString().trim().length === "0") {
            showMessage("Please set current stock size", "danger");
            return;
        }
        if (stockSize.trim() === "0") {
            var contioue = window.confirm("Stock Size is zero,Press OK to proceed");
            if (!contioue) {
                return;
            }
        }
        // setIsProcessing(true);
        var dt = new Date();
        let unique = Math.random().toString().substring(4, 6);
        let prodtid = unique + dt.getTime();
        uploadToFirebase(0, prodtid, []);
    }
    const FilesUpload = async (event) => {
        const imageFiles = event.target.files;
        var imageObj = [];
        setisImageLoading(true)
        for (let index = 0; index < imageFiles.length; index++) {
            const imageFile = imageFiles[index];
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };
            try {
                const compressedFile = await imageCompression(imageFile, options);
                const url = URL.createObjectURL(compressedFile);
                imageObj.push({ url: url, compressedFile: compressedFile })
                setIsImageUpdated(true)
            } catch (error) {
                console.log(error);
            }
        }
        console.log(imageObj);
        setDefaultImageIndex(0);
        setProductImage(imageObj);
        setisImageLoading(false)

    };
    const saveToDb = (prodid, productImages) => {
        var dt = new Date();
        let datestr = dt.getTime();
        var priceobj = [];
        priceobj.push({ date: datestr, amount: productPrice, unitid: selectedUnit, showPrice: showPrice })

        let obj = {
            prodid:prodid,
            catid: selectedCatId,
            title: productTitle,
            price: priceobj,
            maxQuantity,
            minQuantity,
            stockSize,
            hide: hideStatus,
            images: productImages,
            couponid: discountPackageID,
            deleteable: true,
            description
        }
        console.log(obj);
        firebase
            .database()
            .ref(`${dbpathprod}/${selectedCatId}/${prodid}`)
            .update(obj)
            .then(() => {
                clearForm();
                showMessage("Changes saved")
            });

    }
    const filterFloat = (amt) => {
        var val = amt;
        var subst = '';
        var regex = /[^\d.]|\.(?=.*\.)/g;
        val = val.replace(regex, subst);
        return val;
    }
    useEffect(() => {
        fetchCategories()
        fetchUnits()
        fetchCoupons()
    }, [])
    return (
        <div>
            <h2>&nbsp;Add Product</h2>

            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <form onSubmit={e => e.preventDefault()}>
                            <div className='m-1 pt-5'>
                                <label htmlFor='selectboxid'>Select category</label>
                                <select id='selectboxid' className='form-control' value={selectedCatId} onChange={(e) => { setSelectedCatId(e.target.value); }} required>
                                    <option value="">Select Category</option>
                                    {
                                        Object.keys(categories).map(key => {
                                            return (
                                                <option key={key} value={key}>{categories[key].title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='m-1'>
                                <label>Product Title</label>
                                <input required className='form-control' type={"text"} placeholder='Product Title' value={productTitle} onChange={(e) => setProductTitle(e.target.value)} />
                            </div>
                            <div className='m-1'>
                                <label>Product Description</label>
                                <textarea style={{ resize: "none" }} className='form-control' type={"text"} placeholder='Product Description' value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                            </div>
                            <div className='m-1'>
                                <label htmlFor="prodimage">Set Product Image(Click Here)</label>
                                <button type='button' onClick={() => { document.getElementById("prodimage").click(); }} className='btn btn-info  btn-sm'>Add</button>
                                <input className='d-none' multiple accept='image/*' id={"prodimage"} type={"file"} onChange={(event) => FilesUpload(event)} />
                                <br />
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {isImageLoading && (
                                        <div className='image_container'>
                                            <img alt="" style={{ width: 100, height: 100 }} className="mt-3" src={loadingImage} />
                                        </div>
                                    )}

                                    {
                                        productImage.map((element, index) => {
                                            return (
                                                <div key={index} className='image_container'>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            setDefaultImageIndex(index)
                                                            // document.getElementById("prodimage").click();
                                                        }}
                                                        className={'btn btn-rounded btn-sm image_container_button ' + (index === defaultImageIndex ? "btn-success" : "btn-dark")} style={{ left: 0 }}>Default</button>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            var imgArr = productImage;
                                                            imgArr.splice(index, 1);
                                                            if (defaultImageIndex == index) {
                                                                setDefaultImageIndex(0);
                                                            }
                                                            setProductImage(imgArr)
                                                        }}
                                                        className='btn btn-danger btn-rounded btn-sm image_container_button' style={{ right: 0 }}>Delete</button>
                                                    <img alt="" style={{ width: 200, height: 100 }} className="mt-3" src={element.url || defaultImage} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='m-1'>
                                <label>Product Price(Actual)</label>
                                <input required className='form-control' type={"text"} placeholder='Product Price' value={productPrice} onChange={(e) => setProductPrice(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Product Show Price</label>
                                <input className='form-control' type={"text"} placeholder='Product Price' value={showPrice} onChange={(e) => setShowPrice(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label htmlFor='selectboxid2'>Set Quantity Unit</label>
                                <select value={selectedUnit} id='selectboxid2' className='form-control' onChange={(e) => { setSelectedUnit(e.target.value); }} required>
                                    <option value="">Set Quantity Unit</option>
                                    {
                                        Object.keys(units).map(key => {
                                            return (
                                                <option key={key} value={key}>{units[key].title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='m-1'>
                                <label>Max Selling Quantity</label>
                                <input required className='form-control' type={"text"} placeholder='Max Selling Quantity' value={maxQuantity} onChange={(e) => setMaxQuantity(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Min Selling Quantity</label>
                                <input required className='form-control' type={"text"} placeholder='Min Selling Quantity' value={minQuantity} onChange={(e) => setMinQuantity(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Stock Size</label>
                                <input required className='form-control' type={"text"} placeholder='Current Stock Size' value={stockSize} onChange={(e) => setStockSize(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <div>
                                    <label>Make Hide?</label><br />
                                    <Switch onChange={() => { setHideStatus(!hideStatus) }}
                                        checked={hideStatus}
                                    />
                                </div>
                            </div>
                            <div className='m-1'>
                                <label htmlFor='selectboxid3'>Add Discount/Coupon (Optional) </label>
                                <select id='selectboxid3' className='form-control' value={discountPackageID} onChange={(e) => { setDiscountPackageID(e.target.value); }}>
                                    <option value="">Not Applied</option>
                                    {
                                        Object.keys(coupons).map(key => {
                                            return (
                                                <option key={key} value={key}>{coupons[key].title} ({coupons[key].amount}{" "}{coupons[key].type === discountTypeArr[0] ? "%" : "/- Rs"})</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='mb-5 pb-5'>
                                <button disabled={isProcessing} type='submit' onClick={() => { addProduct() }} className='btn btn-info  btn-sm'>{buttonName}</button>
                                <button disabled={isProcessing} type='reset' onClick={() => { clearForm() }} className='btn btn-danger  btn-sm'>Clear</button>
                            </div>
                            <div className='mb-5'></div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default AddProduct

import React from "react";
import styled from "styled-components";

import firebase from "firebase";

import ft from '../freshtables.png'
import alert from "bootstrap/js/src/alert";
const Container = styled.div`
  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }
;

  img {
    flex-grow: 1;
    width: 300px;
  }
;

  .form {
    width: 400px;
    margin-left: 400px;
    border: #c7c7c7 solid 1px;
    padding: 20px;
    font-size: 15px;
    background-color: #f8f8f8;
    -webkit-box-shadow: 4px 4px 14px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 4px 14px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 4px 14px -4px rgba(0, 0, 0, 0.75);
  }
;
  .bottom{
    display: flex;
    align-items: center;

    .forgot{
      display: flex;
      align-items: center;

    }
  };
  display: flex;
  height: 97vh;
  align-items: center;
  margin: 0 30px;

`;

function ValidateEmail(mail) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

const Landing = () => {
  const [details, _setDetails] = React.useState({ email: "", password: "" });

  const CheckVal = () => {
    let bool = true;
    if (details.email === "" || !ValidateEmail(details.email)) {
      window.alert("Please provide a valid email");
      bool = false;
    } else if (details.password === "") {
      window.alert("Please provide a password");
      bool = false;
    }
    return bool;
  };
  const CheckForgotVal = () => {
    if (details.email === "" || !ValidateEmail(details.email)) {
      window.alert("Please provide a valid email");
      return;
    }
    firebase.auth().sendPasswordResetEmail(details.email).then(()=>{
      window.alert("An email with password reset link has been sent to your email")
    }).catch(e=>{
      window.alert(e.message)
    })
  };
  const Login = () => {
    if (CheckVal()) {
      firebase
        .auth()
        .signInWithEmailAndPassword(details.email, details.password)
        .catch((err) => {
          alert(err.message);
        });
    }
  };

  return (
    <Container className="cont">
            <img src={ft} width={300} className=""></img>
            <div className="form">

              <div className="  mb-2 mt-4">Username or Email Address</div>
              <input
                  className="input"
                  onChange={(e) =>
                      _setDetails({ ...details, email: e.target.value })
                  }
                  value={details.email}
              />
              <div className="  mb-2 mt-4">Password</div>
              <input
                  className="input"
                  onChange={(e) =>
                      _setDetails({ ...details, password: e.target.value })
                  }
                  value={details.password}
                  type="password"
              />
              <div>
                <div className="bottom mt-4">
                  <div className="forgot">
                    <button onClick={CheckForgotVal} className="btn btn-link">Forgot Password?</button>
                  </div>
                  <button className="button bg-info text-white px-6 " onClick={Login}>
                    Login
                  </button>
                </div>
              </div>
            </div>
    </Container>
  );
};

export default Landing;

import React, { useState, useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBDataTableV5 } from 'mdbreact';
import firebase from "firebase";
import defaultImage from '../../default.png'
import loadingImage from '../../loading.gif'
import Switch from "react-switch";
import { Link } from "react-router-dom";
import * as Constants from '../StateManager/Constants'

const Products = () => {
    const [categories, setCategories] = useState(null)
    const [coupons, setCoupons] = useState(null)
    const [products, setProducts] = useState([])
    const [units, setUnits] = useState(null)
    const dbpathcat = "customerapp/categories";
    const dbpathUnits = "customerapp/units";
    const dbpathcoupons = "customerapp/coupons";
    const dbpathprod = "customerapp/products";//products/catid/productid
    const imagepathprod = "products/images";//products/images/productid/all_images
    const [productsDatatable, setProductsDatatable] = useState({
        columns: [
            {
                label: 'Category',
                field: 'category',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Category',
                },
            },
            {
                label: 'Product',
                field: 'productTitle',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Product',
                },
            },
            {
                label: 'Description',
                field: 'description',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Description',
                },
            },
            {
                label: 'Price',
                field: 'price',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Price',
                },
            },
            {
                label: 'In Stock',
                field: 'stock',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'In Stock',
                },
            },
            {
                label: 'Action',
                field: 'action',
                width: 100,
                attributes: {
                    'aria-controls': 'DataTable',
                    'aria-label': 'Action',
                },
            },
        ], rows: []
    });
    const getCategory = (catid) => {
        return categories[catid];
    };
    const getUnit = (unitid) => {
        return units[unitid].title;
    };
    const getCurrentPrice = (priceObj = []) => {
        let lastObj = {};
        priceObj.forEach(element => {
            lastObj = element
        });
        lastObj.unitTitle = getUnit(lastObj.unitid);
        return lastObj;
    };
    const getRow = (productid, Product) => {
        // console.log(data[catid][prodid]);
        // console.log(getCategory(data[catid][prodid].catid));
        // console.log(getCurrentPrice(data[catid][prodid].price));
        let lastPrice = getCurrentPrice(Product.price)
        let obj = {
            category: getCategory(Product.catid).title,
            productTitle: Product.title,
            description: Product.description,
            price: lastPrice.amount + "/" + lastPrice.unitTitle,
            stock: Product.stockSize,
            action: (<div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                <Link id="editProductLink" onClick={() => {

                    Constants.setPageTitle("/edit-product");
                }} className="link d-none" to={"/edit-product"}></Link>
                <div style={{ cursor: "pointer" }} onClick={() => {
                    Constants.selectedProduct.prod = Product;
                    Constants.selectedProduct.categories = categories;
                    Constants.selectedProduct.units = units;
                    Constants.selectedProduct.catID = Product.catid;
                    Constants.selectedProduct.prodID = productid;
                    document.getElementById("editProductLink").click();
                    console.log("product id " + productid);
                }}><ion-icon name="pencil-sharp" style={{ marginRight: 5, cursor: "pointer" }} className="icon"></ion-icon></div>
                {/* <div style={{ cursor: "pointer" }} ><ion-icon name="trash" style={{ marginRight: 5, cursor: "pointer", color: "#f00" }} className="icon imr3"></ion-icon></div> */}
            </div>)
        }
        return obj;
    }
    const fetchCategories = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcat)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data)
                    setCategories(data)
            });
    };
    const fetchUnits = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathUnits)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data)
                    setUnits(data)
            });
    };
    const fetchCoupons = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcoupons)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data){
                    Constants.selectedProduct.coupons = data;
                    setCoupons(data)
                }
            });
    };
    const fetchProducts = async () => {
        console.log("Fetch product called");
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathprod)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                let _products = []
                let originalProduct = []
                if (data) {
                    Object.keys(data).map((catid) => {
                        // console.log(data[catid]);
                        Object.keys(data[catid]).map((prodid) => {
                            // console.log(data[catid][prodid]);
                            // console.log(getCategory(data[catid][prodid].catid));
                            // console.log(getCurrentPrice(data[catid][prodid].price));
                            _products.push(getRow(prodid, data[catid][prodid]))
                            originalProduct.push(data[catid][prodid]);
                        })
                    })
                    productsDatatable.rows = _products;
                    // console.log(_products);
                    setProductsDatatable({ ...productsDatatable });
                    setProducts(originalProduct);

                }
            });
    };
    useEffect(() => {
        fetchCategories()
    }, [])
    useEffect(() => {
        if (categories) {
            console.log("Category loaded");
            fetchUnits()
        }


    }, [categories])
    useEffect(() => {
        if (units) {
            console.log("units loded");
            fetchCoupons()
            console.log("categories,units loaded");
            // console.log(Object.keys(categories).length, Object.keys(units).length);
            if (Object.keys(categories).length > 0 && Object.keys(units).length > 0) {
                fetchProducts();
            }
        }
    }, [units])
    useEffect(() => {
        if (coupons) {
            console.log("couponse loaded");
            console.log(Constants.selectedProduct.coupons);
            // setCoupons(coupons);
        }
    }, [coupons])
    return <>

        <MDBDataTableV5
            scrollX hover entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={productsDatatable} />
    </>
}

export default Products

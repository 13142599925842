import React from "react";
import styled from "styled-components";
import firebase from "firebase";

import UploadCategory from "./UploadVegetables/UploadToCategory";
import CreateCategory from "./UploadVegetables/CreateCategory";

const Container = styled.div``;

const UploadVegetables = () => {
  const [categories, _setCategories] = React.useState([]);

  const FetchCategory = () => {
    firebase
      .database()
      .ref(`categories`)
      .once("value", (snap) => {
        let counter = 0;
        let ArrayData = [];
        snap.forEach((data) => {
          counter++;
          ArrayData.push({ ...data.val(), name: data.key });
          if (counter === Object.keys(snap.val()).length) {
            _setCategories([...ArrayData]);
          }
        });
      });
  };

  React.useEffect(() => {
    FetchCategory();
  }, []);

  return (
    <Container>
      <h3 className="title is-3 has-text-black">Upload vegetables</h3>
      <div className="columns">
        <div className="column is-6">
          <div className="mx-2">
            <UploadCategory categories={categories} />
          </div>
        </div>
        <div className="column is-6">
          <CreateCategory FetchCategory={FetchCategory} />
        </div>
      </div>
    </Container>
  );
};
export default UploadVegetables;

import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import { store } from 'react-notifications-component';
import imageCompression from "browser-image-compression";
import defaultImage from '../../default.png'
import Switch from "react-switch";

const Coupons = () => {
    const [coupons, setCoupons] = useState([])
    const [couponID, setCouponID] = useState("")
    const [couponTitle, setCouponTitle] = useState("")
    const [discountAmount, setDiscountAmount] = useState("")
    const [discountType, setDiscountType] = useState("precentage")
    const [isProcessing, setIsProcessing] = useState(false)
    const [hasExpiry, setHasExpiry] = useState(false)
    const [isEnabled, setIsEnabled] = useState(true)
    const [startingDate, setStartingDate] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [buttonName, setButtonName] = useState("Add")
    const dbpath = "customerapp/coupons";
    const discountTypeArr = ["precentage", "flat"];
    const discountTypeName = { precentage: "Precentage", flat: "Flat" };


    let tableIndex = 0;
    const clearForm = () => {
        setCouponID("")
        setCouponTitle("")
        setDiscountAmount("")
        setDiscountType(discountTypeArr[0])
        setHasExpiry(false)
        setStartingDate("")
        setExpiryDate("")
        setIsProcessing(false)
        setIsEnabled(true)
        setButtonName("Add");
    }
    const setValuesToForm = (key, Coupon) => {
        console.log(getMsDate(Coupon.startingDate,2));
        setCouponID(key);
        setCouponTitle(Coupon.title)
        setDiscountAmount(Coupon.amount)
        setDiscountType(Coupon.type)
        setHasExpiry(Coupon.hasExpiry)
        setIsEnabled(Coupon.isEnabled||false)
        setStartingDate(getMsDate(Coupon.startingDate,2))
        setExpiryDate(getMsDate(Coupon.expiryDate,2))
        setButtonName("Update");
        window.scrollTo(0, 0)
    }
    const fetchCoupons = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpath)
            .once(`value`, (snap) => {
                const data = snap.val();
                tableIndex = 0;
                console.log(data);
                if (data)
                    setCoupons(data)
            });
    }
    const getMsDate = (ms, type = 1) => {
        if (ms != undefined) {
            if (ms.toString().length > 0) {
                try {
                    var d = new Date(ms);
                    var datestring = "";
                    if (type == 2) {
                        datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
                    } else {
                        datestring = ("0" + d.getDate()).slice(-2) + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" +
                            d.getFullYear();
                    }
                    return datestring;
                } catch (e) { }
            }
        }
        return "";
    }
    const showMessage = (message, type = "success") => {
        store.addNotification({
            title: "Notification",
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    }
    const addCoupon = () => {
        if (couponTitle.trim().length === 0) {
            showMessage("Coupon title is required", "danger");
            return;
        }
        if (discountAmount.trim().length == 0) {
            showMessage("Discount amount is required", "danger");
            return;
        }
        if (hasExpiry == true) {
            if (startingDate.trim().length == 0) {
                showMessage("Starting date is required", "danger");
                return;
            }
            if (expiryDate.trim().length == 0) {
                showMessage("Expiry date is required", "danger");
                return;
            }
        }
        setIsProcessing(true);
        var dt = new Date();
        let unique = Math.random().toString().substring(4, 6);
        let cupnid = unique + dt.getTime();
        let copnID = cupnid;
        let stDate = "";
        let endDate = "";
        if (startingDate != "") {
            let startDate = new Date(startingDate);
            stDate = startDate.getTime()
        }
        if (expiryDate != "") {
            let expDate = new Date(expiryDate);
            endDate = expDate.getTime()
        }

        if (couponID != "") {
            copnID = couponID;
        }
        let obj = {
            title: couponTitle,
            type: discountType,
            amount: discountAmount,
            hasExpiry: hasExpiry,
            startingDate: stDate,
            expiryDate: endDate,
            createdOn: dt.getTime()
        };
        console.log(obj);
        firebase
            .database()
            .ref(`${dbpath}/${copnID}`)
            .update(obj)
            .then(() => {
                clearForm();
                showMessage("Changes Saved")
                fetchCoupons();
            });
    }

    useEffect(() => {
        fetchCoupons();
    }, [])
    return (
        <div className="container">
            <div className="row">
                <div className="col-sm">
                    <form onSubmit={e => e.preventDefault()}>
                        <div className='m-1 pt-5'>
                            <label>Coupon Title</label>
                            <input required className='form-control' type={"text"} placeholder='Coupon Title' value={couponTitle} onChange={(e) => setCouponTitle(e.target.value)} />
                        </div>
                        <div className='m-1 row'>
                            <div className='col-sm-4 p-0 pr-1'>
                                <label>Coupon Type</label>
                                <select className='form-control' onChange={(e) => { console.log(e.target.value); setDiscountType(e.target.value) }}>
                                    <option value={discountTypeArr[0]}>{discountTypeName[discountTypeArr[0]]}</option>
                                    <option value={discountTypeArr[1]}>{discountTypeName[discountTypeArr[1]]}</option>
                                </select>
                            </div>
                            <div className='col-sm-6 p-0 pr-1'>
                                <label>Discount Amount</label>
                                <input required className='form-control' type={"text"} placeholder='Discount Amount'
                                    value={discountAmount} onChange={(e) => {
                                        var val = e.target.value;
                                        var subst = '';
                                        var regex = /[^\d.]|\.(?=.*\.)/g;
                                        val = val.replace(regex, subst);
                                        setDiscountAmount(val)
                                    }} />
                            </div>
                            <div className='col-sm-1 p-0 '>
                                <br /> <br />
                                {discountType === discountTypeArr[0] ? "%" : "Rs"}
                            </div>
                        </div>
                        <div className='m-1'>
                            <div>
                            <label>This coupon has expiry date?</label><br />
                            <Switch onChange={() => { setHasExpiry(!hasExpiry) }}
                                checked={hasExpiry}
                            />
                            </div> 
                            <div>
                            <label>Is Enable</label><br />
                            <Switch onChange={() => { setIsEnabled(!isEnabled) }}
                                checked={isEnabled}
                            />
                            </div>
                        </div>
                        {hasExpiry && (
                            <div className='m-1'>
                                <label>Starting Date</label>
                                <input type={"date"} className='form-control' placeholder='Starting date' value={startingDate} onChange={(e) => setStartingDate(e.target.value)} />
                            </div>
                        )}
                        {(hasExpiry && startingDate != "") && (
                            <div className='m-1'>
                                <label>Expiry Date</label>
                                <input type={"date"} className='form-control' min={startingDate} placeholder='Expiry date' value={expiryDate} onChange={(e) => setExpiryDate(e.target.value)} />
                            </div>
                        )}


                        <div>
                            <button disabled={isProcessing} type='submit' onClick={() => { addCoupon() }} className='btn btn-info  btn-sm'>{buttonName}</button>
                            <button disabled={isProcessing} type='reset' onClick={() => { clearForm() }} className='btn btn-danger  btn-sm'>Clear</button>
                        </div>
                    </form>
                </div>
                <div className="col-sm" style={{ overflow: 'hidden auto', height: '90vh' }}>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Price</th>
                                <th scope="col">Expiry</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.keys(coupons).map(key => {
                                    return (
                                        <tr key={key}>
                                            <th scope="row">{++tableIndex}</th>
                                            <td>{coupons[key].title}</td>
                                            <td> {coupons[key].amount}{" "}{coupons[key].type === discountTypeArr[0] ? "%" : "/- Rs"} </td>
                                            <td>{coupons[key].hasExpiry ? (
                                                getMsDate(coupons[key].startingDate)
                                                + " To " +
                                                getMsDate(coupons[key].expiryDate)
                                            ) : "No Expiry"} </td>
                                            <td >
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                                    <div style={{ cursor: "pointer" }} onClick={() => { setValuesToForm(key, coupons[key]) }}><ion-icon name="pencil-sharp" style={{ marginRight: 5, cursor: "pointer" }} className="icon"></ion-icon></div>
                                                    {/* <div style={{ cursor: "pointer" }} ><ion-icon name="trash" style={{ marginRight: 5, cursor: "pointer", color: "#f00" }} className="icon imr3"></ion-icon></div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Coupons

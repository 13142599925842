import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "react-notifications-component/dist/theme.css";
import Modal from "react-modal";
import AppRoutes from './AppRoutes'
import { BrowserRouter } from "react-router-dom";
const element = document.getElementById("root");
Modal.setAppElement(element);

ReactDOM.render(
  <BrowserRouter>
      <AppRoutes />
  </BrowserRouter>,
  element
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from 'react'
import firebase from "firebase";
import * as Constants from '../StateManager/Constants'
import { store } from 'react-notifications-component';

function getCollected(orderID,deliveryBoy){
    console.log(deliveryBoy)
    if(deliveryBoy){
        if(deliveryBoy.money){
            if(deliveryBoy.money[orderID]){
                return deliveryBoy.money[orderID].collected;
            }
        }
    }
    return 0;
}

function OrderDetails({ OrderDetail, Units,Users,DeliveryBoy }) {

    console.log('OrderDetail ', DeliveryBoy);

    const dbpathOrders = "customerapp/orders";
    const [delivertBoyID, setDelivertBoyID] = useState("")
    const [updated, setUpdate] = useState("")
    const [pending,setPending]=useState(OrderDetail.pending?OrderDetail.pending:0);
    const [collected,setCollected]=useState(0);
    const [UserDetails, setUserDetails] = useState({
        phone: "",
        name: "",
        email: "",
        landmark: "",
        pincode: "",
        address: "",
      })
    useEffect(()=>{
        const deliveryBoy=DeliveryBoy.find((d=>d.userid===delivertBoyID));
        setCollected(getCollected(OrderDetail.orderid,deliveryBoy))
    },[delivertBoyID])
    var getImages = (imgObj) => {
        let obj = [];
        imgObj.forEach(element => {
            obj.push(element)
        });
        return obj ? obj.sort((a, b) => b.isDefault - a.isDefault) : undefined;
    }
    const addDeliveryBoy = ()=>{
        if(delivertBoyID.toString().trim().length>0){
            let deliveryBoy = OrderDetail.deliveryBoyIDs||[];
            let dt = new Date()
            deliveryBoy.push({time:dt.getTime(),userid:delivertBoyID.toString()+""})
            OrderDetail.deliveryBoyIDs = deliveryBoy;
            Constants.updatedOrder.item = {...OrderDetail};
            changeStatus("Pendding",true);
            firebase.database()
                    .ref(`${dbpathOrders}/${OrderDetail.userid}/${OrderDetail.orderid}/deliveryBoyIDs`)
                    .set(deliveryBoy)
                    .then(() => {
                        console.log("Updated");
                        setUpdate(Math.random())
                        store.addNotification({
                            title: "Notification",
                            message: "Delivery boy assigned",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true,
                            },
                        });
                    });
        }else{
            alert("Please select one delevery boy to assign")
        }

    }
    const changeStatus = (type,force)=>{

        let cnf;
        if(!force) cnf=window.confirm("Do you want to mark this order as "+type);
        if(force||cnf){

            let ostat = OrderDetail.orderStatus;
            let dt = new Date()
            if(type == "Canceled"){
                ostat.push({ time: dt.getTime(), status: "Canceled", msg: "Order canceled by admin", deliveryDate: "" })
                OrderDetail.status = "Canceled";
            }
            if(type == "Pendding"){
                ostat.push({ time: dt.getTime(), status: "Canceled", msg: "Order pendding marked by admin", deliveryDate: "" })
                OrderDetail.status = "Pendding";
            }
            if(type == "Processing"){
                ostat.push({ time: dt.getTime(), status: "Canceled", msg: "Order processing marked by admin", deliveryDate: "" })
                OrderDetail.status = "Processing";
            }
            if(type == "Dispatched"){
                ostat.push({ time: dt.getTime(), status: "Canceled", msg: "Order dispatched marked by admin", deliveryDate: "" })
                OrderDetail.status = "Dispatched";
            }
            if(type == "Delivered"){
                const deliveryBoy=DeliveryBoy.findIndex((d=>d.userid===delivertBoyID));
                if(deliveryBoy!==-1) {
                    firebase.database()
                        .ref('deliveryEarnings/d2c').once("value").then(s=>{
                        if(!DeliveryBoy[deliveryBoy].money)DeliveryBoy[deliveryBoy].money={};
                        DeliveryBoy[deliveryBoy].money[OrderDetail.orderid]={...deliveryBoy.money,earned:s.val()};
                        firebase.database()
                            .ref(`customerapp/delivery/users/${DeliveryBoy[deliveryBoy].fbid}/money/${OrderDetail.orderid}`)
                            .update({earned:s.val(),type:"d2c"});
                    })

                }
                ostat.push({ time: dt.getTime(), status: "Canceled", msg: "Order delivered marked by admin", deliveryDate: dt.getTime() })
                OrderDetail.status = "Delivered";
            }
            Constants.updatedOrder.item = {...OrderDetail};
            firebase.database()
                .ref(`${dbpathOrders}/${OrderDetail.userid}/${OrderDetail.orderid}`)
                .update(OrderDetail)
                .then(() => {
                    console.log("Updated");
                    setUpdate(Math.random())
                    store.addNotification({
                        title: "Notification",
                        message: "Status Updated",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                });
        }

    }
    async function updatePendingCollected(){
        const deliveryBoy=DeliveryBoy.findIndex((d=>d.userid===delivertBoyID));
        await firebase.database()
            .ref(`${dbpathOrders}/${OrderDetail.userid}/${OrderDetail.orderid}`)
            .update({pending})

        if(deliveryBoy!==-1) {
            if(!DeliveryBoy[deliveryBoy].money)DeliveryBoy[deliveryBoy].money={};
            DeliveryBoy[deliveryBoy].money[OrderDetail.orderid]={...deliveryBoy.money,collected};
            console.log(DeliveryBoy)
            firebase.database()
                .ref(`customerapp/delivery/users/${DeliveryBoy[deliveryBoy].fbid}/money/${OrderDetail.orderid}`)
                .update({collected,type:"d2c"})
                .then(() => {
                    store.addNotification({
                        title: "Notification",
                        message: "Status Updated",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000,
                            onScreen: true,
                        },
                    });
                });
        }

    }
    const getLastStatus = ()=>{
        let lastStatus = "";
        OrderDetail.orderStatus.forEach(element => {
            lastStatus = element;
        });
        return lastStatus;
    }
    const getLastDeliveryBoy = (deliveryBoyIDs=[])=>{
        let lastUser = "";
        deliveryBoyIDs.forEach(element => {
            lastUser = element.userid;
        });
        console.log("last delivery boy ",DeliveryBoy);
        setDelivertBoyID(lastUser)
        return lastUser;
    }
    const getUser = ()=>{
        console.log("load user");
        Object.keys(Users).map((uid) => {
            if (Users[uid].phone.toString() === OrderDetail.userid.toString()) {
                setUserDetails(Users[uid])
            }
        });
    }
    useEffect(()=>{
        getUser()
        getLastDeliveryBoy(OrderDetail.deliveryBoyIDs||[]);
    },[])
    const getTimeStr = (timeStr) => {
        var datestring = "";
        if (timeStr) {
            var dt = new Date(timeStr);
            datestring = ("0" + dt.getDate()).slice(-2) + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" +
                dt.getFullYear() + " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2);
        }
        return datestring;
    }
    return (
        <div className='container'>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-12'>Order ID:{OrderDetail.orderid}

                    </div>
            </div>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-6'>Total Items:{OrderDetail.products.length}</div>
                <div className='col-md-6'>Total Amount:₹{OrderDetail.totalAmount} </div>
            </div>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-6'>Order Made on:{getTimeStr(OrderDetail.createdAt)}</div>
                <div className='col-md-6 pull-right'>Status:{OrderDetail.status}</div>
            </div>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-6'>Last Status:{getLastStatus().msg}</div>
                <div className='col-md-6 pull-right'>{getLastStatus().deliveryDate!=""?("Delivered On:"+getTimeStr(getLastStatus().deliveryDate)):""}</div>
            </div>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-6'>Name:{UserDetails.name}</div>
                <div className='col-md-6 pull-right'>Address:{UserDetails.address}</div>
            </div>
            <div className='row col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-6'>Phone:{UserDetails.phone}</div>
                <div className='col-md-6 pull-right'>Pin Code:{UserDetails.pincode}</div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-12'>
                <table className="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Title</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            OrderDetail.products.map((product) => {
                                let image = getImages(product.images)[0];
                                return (
                                    <tr key={Math.random()}>
                                        <th ><img style={{ width: "95px" }} src={image.url} alt="" /></th>
                                        <td>{product.title}</td>
                                        <td>Price:₹{product.price[0].amount + "/" + Units[product.price[0].unitid].title}</td>
                                        <td>Quantity:{product.quantity} {Units[product.price[0].unitid].title}</td>
                                        <td>Amount:₹{product.price[0].amount * product.quantity}</td>
                                    </tr>
                                );
                            })
                        }
                        <tr >
                                        <th ></th>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>Total :₹ {OrderDetail.totalAmount}</td>
                                    </tr>
                    </tbody>
                </table>
                <div className='row col-sm-12 col-md-12 col-lg-12'>
                    <div className='col-md-3'>
                        <button type="button" onClick={() => { changeStatus("Pendding") }}
                            className="btn btn-rounded btn-sm  btn-info">Set Pendding</button>
                    </div>
                    <div className='col-md-3'>
                        <button type="button" onClick={() => { changeStatus("Processing") }}
                            className="btn btn-rounded btn-sm  btn-success">Set Processing</button>
                    </div>
                    <div className='col-md-3'>
                        <button type="button" onClick={() => { changeStatus("Dispatched") }}
                            className="btn btn-rounded btn-sm  btn-primary">Set Dispatched</button>
                    </div>
                    <div className='col-md-3'>
                        <button type="button" onClick={() => { changeStatus("Delivered") }}
                            className="btn btn-rounded btn-sm  btn-warning">Set Delivered</button>
                    </div>

                    <div className='col-md-3'>
                        <button type="button" onClick={() => { changeStatus("Canceled") }}
                            className="btn btn-rounded btn-sm  btn-danger">Set Canceled</button>
                    </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                <div className='col-md-12'>
                    <label>Assing to delivery boy </label>
                    </div>
                    <div className='col-md-12'>
                    <select  className='form-control'
                     value={delivertBoyID} onChange={(e) => { setDelivertBoyID(e.target.value); }} >
                        <option value={""}>Not Assigned</option>
                        {
                            Object.keys(DeliveryBoy).map((duid)=>{

                                return <option key={Math.random()} value={DeliveryBoy[duid].userid}>{DeliveryBoy[duid].name}</option>;
                            })
                        }
                    </select>
                    </div>
                    <div className='col-md-12'>
                        <button type="button" onClick={() => { addDeliveryBoy() }}
                            className="btn btn-rounded btn-sm  btn-danger">Assign</button>
                    </div>
                </div>
                <div className="container">
                    <div className="form-group">
                        <div className="form-label">Pending Money</div>
                        <input value={pending} onChange={e=>setPending(parseInt(e.target.value))} type="number" className="form-control" placeholder="Pending Money"/>
                    </div>
                    <div className="form-group">
                        <div className="form-label">Money Collected</div>
                        <input value={collected} disabled={delivertBoyID===""} onChange={e=>setCollected(parseInt(e.target.value))} type="number" className="form-control" placeholder="Money Collected"/>
                    </div>
                    <button disabled={delivertBoyID===""} onClick={updatePendingCollected} className="btn btn-info">Update</button>
                </div>
            </div>

        </div>
    )
}

export default OrderDetails

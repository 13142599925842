import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import firebase from "firebase";
import { store } from "react-notifications-component";
import { IoMdCloseCircle } from "react-icons/io";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const Container = styled.div`
  h1 {
    color: #000;
  }
`;

const ProductCard = (props) => {
  const [data, _setData] = useState({});
  const [offerModalStatus, setOfferModalStatus] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [productNewprice, setProductNewprice] = React.useState(0);
  const [penddingProductArr, setPenddingProductArr] = React.useState(
    props.pendngOrder
  );
  const [savemode, setSavemode] = React.useState(0);
  // console.log(props.pendngOrder[1]["userid"],props.pendngOrder[1]["orders"]);

  React.useEffect(() => {
    // console.log("dataObj ",dataObj);
    var dataObj = props.productCardData;
    dataObj.bulkOffers = props.productCardData.bulkOffers || [];
    let prevPric =
      props.productCardData.prevPrice || props.productCardData.price;
    dataObj.prevPrice = prevPric;
    setProductNewprice(prevPric);
    _setData(dataObj);
  }, [props]);

  const updatePendingOrders = () => {
    setTimeout(() => {
      data.prevPrice = data.price;
      _setData(data);
    }, 200);
    // console.log(penddingProductArr);
    for (var ind in penddingProductArr) {
      // console.log(penddingProductArr[ind]["userid"],penddingProductArr[ind]["orders"]);
      for (var orderid in penddingProductArr[ind]["orders"]) {
        let orderDet = penddingProductArr[ind]["orders"][orderid];
        // console.log(orderDet.status,orderDet.product);
        if (orderDet.status === "processed") {
          let isUpdated = false;
          let totalPrice = 0;
          for (var prodIndex in orderDet.product) {
            // console.log(orderDet.product[prodIndex].productName,orderDet.product[prodIndex]);
            // console.log(orderDet.product[prodIndex].productName,orderDet.product[prodIndex]);
            if (
              orderDet.product[prodIndex].productName ===
              props.productCardData.itemDatabase
            ) {
              console.log("Match fond");
              orderDet.product[prodIndex].price = productNewprice;
              isUpdated = true;
              // console.log(penddingProductArr[ind].userid, ind, orderid, orderDet);
            }
            let proPrice =
              orderDet.product[prodIndex].price *
              orderDet.product[prodIndex].quantity.quantity;
            totalPrice = totalPrice + proPrice;
          }
          orderDet.price = totalPrice > 0 ? totalPrice : orderDet.price;
          if (isUpdated) {
            // console.log(orderDet.product);
            // console.log(orderid);
            // console.log(orderDet);
            let phone = penddingProductArr[ind].userid;
            firebase
              .database()
              .ref(`order/${phone}/${orderid}`)
              .update({ price: totalPrice, product: orderDet.product })
              .then(() => {
                console.log("updated");
              });
          }
        }
      }
    }
  };
  const Save = (adjmode) => {
    // console.log(penddingProductArr);
    // console.log(props.selectedCategory,props.productCardData.itemDatabase);
    console.log(adjmode, adjmode === 1);
    firebase
      .database()
      .ref(
        `vegetables/${props.selectedCategory}/${props.productCardData.itemDatabase}`
      )
      .update({ ...data })
      .then(() => {
        if (adjmode === 1) {
          updatePendingOrders();
        }
        store.addNotification({
          title: "Notification",
          message: "Changes saved",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000,
            onScreen: true,
          },
        });
      });
  };

  const offerheader = {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#000",
  };

  const offer = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000",
  };

  const deleteOffer = (index) => {
    let newArr = data.bulkOffers;
    newArr.splice(index, 1);
    _setData({ ...data, bulkOffers: newArr });
  };

  const offerModalStatusChange = () => {
    setOfferModalStatus(!offerModalStatus);
  };

  const calculateMargin = (mrp, price) => {
    let margin = ((mrp - price) / mrp) * 100;
    return margin.toFixed(2);
  };

  const BulkOfferModal = () => {
    const [bulkOffer, setBulkOffer] = useState({
      price: 0,
      minQuantity: 0,
    });

    useEffect(() => {
      if (selectedOffer) {
        setBulkOffer(selectedOffer.data);
      }
    }, [selectedOffer]);

    const handleBulkOfferChange = (e) => {
      setBulkOffer({ ...bulkOffer, [e.target.name]: e.target.value });
    };

    const handleOfferSave = () => {
      let newArr = data.bulkOffers;
      if (selectedOffer) {
        newArr[selectedOffer.index] = {
          ...bulkOffer,
          margin: calculateMargin(Number(data.mrp), Number(bulkOffer.price)),
        };
      } else {
        newArr.push({
          ...bulkOffer,
          margin: calculateMargin(Number(data.mrp), Number(bulkOffer.price)),
        });
      }
      _setData({ ...data, bulkOffers: newArr });
      setSelectedOffer(null);
      offerModalStatusChange();
    };

    return (
      <Dialog
        open={offerModalStatus}
        onClose={offerModalStatusChange}
        fullWidth
      >
        <DialogTitle>Create New Slot</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                name="price"
                type="number"
                variant="outlined"
                label="Price"
                placeholder="Enter Price"
                fullWidth
                required
                value={bulkOffer.price}
                onChange={handleBulkOfferChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Margin:{" "}
                {calculateMargin(Number(data.mrp), Number(bulkOffer.price))}%
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="minQuantity"
                type="number"
                label="Min. Quantity"
                placeholder="Enter Min. Quantity"
                fullWidth
                required
                value={bulkOffer.minQuantity}
                onChange={handleBulkOfferChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={offerModalStatusChange}>Cancel</Button>
          <Button onClick={handleOfferSave}>Add</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Container className="card mx-2 px-3 py-3">
      <BulkOfferModal />
      <div className="columns is-multiline pr-2">
        <div className="column is-12">
          <h3 className="title is-2 ">{data.productName}</h3>
        </div>
        <div className="column is-6 my-0 py-0">
          {data.prevPrice != data.price && (
            <span>Previous Price {data.prevPrice || data.price}</span>
          )}
          <h3 className="subtitle is-5 mb-2">Product MRP</h3>
          <input
            onChange={(e) => {
              _setData({ ...data, mrp: e.target.value });
            }}
            value={data.mrp}
            className="input"
          />
          <div className="mt-2 d-flex justify-content-between">
            <h3 className="subtitle mb-0 is-5">Default Price</h3>
            <p className="mb-0">
              Default Margin:{" "}
              {calculateMargin(Number(data.mrp), Number(data.price))}%
            </p>
          </div>
          <input
            onChange={(e) => {
              _setData({ ...data, price: e.target.value });
            }}
            value={data.price}
            className="input"
          />
          <h3 className="subtitle is-5 mb-2">Adjust</h3>
          <input
            onChange={(e) => {
              setProductNewprice(e.target.value);
              _setData({ ...data, price: e.target.value });
            }}
            value={productNewprice}
            className="input mt-1"
          />
          <div className="row is-12">
            <div className="column is-6">
              <h3 className="subtitle is-5 mb-2">Hide Product</h3>
              <Switch
                onChange={() => {
                  _setData({ ...data, hide: !data.hide });
                }}
                checked={data.hide === true ? true : false}
              />
            </div>
            <div className="column is-6">
              <h3 className="subtitle is-5 mb-2">Out Of Stock</h3>
              <Switch
                onChange={() => {
                  _setData({ ...data, outOfStock: !data.outOfStock });
                }}
                checked={data.outOfStock === true ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="column is-6 my-0 py-0">
          <div className="row is-12 justify-content-end mb-1">
            <button
              onClick={() => {
                setSelectedOffer(null);
                offerModalStatusChange();
              }}
              style={{ width: "110px", height: "30px" }}
              className="button is-link"
            >
              Add Slot
            </button>
          </div>
          {data.bulkOffers &&
            data.bulkOffers[0] &&
            data.bulkOffers.map((item, index) => (
              <div style={{ position: "relative", marginBottom: 3 }}>
                <IoMdCloseCircle
                  onClick={() => deleteOffer(index)}
                  size={20}
                  style={{
                    zIndex: 1,
                    position: "absolute",
                    right: -17,
                    top: -2,
                  }}
                />
                <div
                  className="row is-12"
                  key={index}
                  onClick={() => {
                    setSelectedOffer({
                      data: item,
                      index: index,
                    });
                    offerModalStatusChange();
                  }}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                  }}
                >
                  <div className="column is-4">
                    <div className="is-12" style={offerheader}>
                      Price
                    </div>
                    <div className="is-12" style={offer}>
                      Rs {item.price}
                    </div>
                  </div>
                  <div className="column is-4">
                    <div className="is-12" style={offerheader}>
                      Margin
                    </div>
                    <div className="is-12" style={offer}>
                      {item.margin}%
                    </div>
                  </div>
                  <div className="column is-4">
                    <div className="is-12" style={offerheader}>
                      Min. Qty.
                    </div>
                    <div className="is-12" style={offer}>
                      {item.minQuantity}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div
          className="column is-12 "
          style={{ justifyContent: "space-evenly", display: "flex" }}
        >
          <button
            onClick={() => {
              Save(0);
            }}
            style={{ width: "45%" }}
            className="button is-link"
          >
            Save
          </button>
          <button
            onClick={() => {
              Save(1);
            }}
            style={{ width: "45%" }}
            className="button is-link"
          >
            Adjust
          </button>
        </div>
      </div>
    </Container>
  );
};
export default ProductCard;

import React from "react";
import styled from "styled-components";
import firebase from "firebase";
import imageCompression from "browser-image-compression";

const Container = styled.div`
  img {
    border-radius: 20px;
  }
`;

const CreateProduct = ({ category, name }) => {
  const [ImageLink, _setImageLink] = React.useState("");
  const [file, _setFile] = React.useState(null);
  const [productName, _setProductName] = React.useState("");
  const [loading, _setLoading] = React.useState(false);
  const [data, _setData] = React.useState({
    prefix: "",
    maxQuantity: "",
    price: "",
    description: "",
    minQuantity: "",
  });

  const FilesUpload = async (event) => {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      const url = URL.createObjectURL(compressedFile); // write your own logic
      _setImageLink(url);
      _setFile(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const Upload = async () => {
    if (productName === "") {
      return alert("Please provide a product name");
    } else if (data.prefix === "") {
      return alert("Please provide a prefix");
    } else if (data.minQuantity === "") {
      return alert("Please provide a min quantity Sold");
    } else if (data.maxQuantity === "") {
      return alert("Please provide a max quantity Sold");
    } else if (data.price === "") {
      return alert("Please provide a price");
    }

    _setLoading(true);
    await firebase
      .storage()
      .ref(`vegetables/${category}/${productName}`)
      .put(file)
      .then(() => {
        firebase
          .storage()
          .ref(`vegetables/${category}/${productName}`)
          .getDownloadURL()
          .then((url) => {
            firebase
              .database()
              .ref(`vegetables/${category}/${productName}`)
              .set({
                url: url,
                productName,
                prefix: data.prefix,
                maxQuantity: data.maxQuantity,
                minQuantity: data.minQuantity,
                price: data.price,
                miniDescription: data.description,
              })
              .then(() => {
                firebase
                  .database()
                  .ref(`search/${productName}`)
                  .set({
                    title: productName.toLowerCase(),
                    path: `${category}/${productName}`,
                  });
                _setFile(null);
                _setImageLink("");
                _setProductName("");
                _setLoading(false);
                _setData({
                  prefix: "",
                  maxQuantity: "",
                  price: "",
                  description: "",
                  minQuantity: "",
                });
                ToggleDrawer();

                alert("Product Created");
              });
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };
  const [productCreateOpen, _setProductCreateOpen] = React.useState(false);
  const ToggleDrawer = () => _setProductCreateOpen(!productCreateOpen);

  return (
    <>
      <div
        className="columns card py-2 px-2 is-vcentered my-2 is-multiline"
        key={name}
      >
        <div className="column is-6">
          <h3>{name}</h3>
        </div>
        {productCreateOpen ? (
          <div className="column is-12">
            <Container className="columns is-multiline mx-2">
              <div className="column is-12">
                <h3 className="subtitle has-text-black is-4">Create Product</h3>
              </div>
              <div className="column is-12">
                <input
                  className="input"
                  value={productName}
                  onChange={(e) => _setProductName(e.target.value)}
                  placeholder="Product Name"
                />
                <input
                  className="input mt-2"
                  value={data.prefix}
                  onChange={(e) =>
                    _setData({ ...data, prefix: e.target.value })
                  }
                  placeholder="Prefix (of Quantity)"
                />
                <input
                  className="input mt-2"
                  value={data.minQuantity}
                  onChange={(e) =>
                    _setData({ ...data, minQuantity: e.target.value })
                  }
                  placeholder="Enter Min Quantity"
                />
                <input
                  className="input mt-2"
                  value={data.maxQuantity}
                  onChange={(e) =>
                    _setData({ ...data, maxQuantity: e.target.value })
                  }
                  placeholder="Enter Max Quantity"
                />
                <input
                  className="input mt-2"
                  value={data.price}
                  onChange={(e) => _setData({ ...data, price: e.target.value })}
                  placeholder="Price of 1"
                />
                <input
                  className="input mt-2"
                  value={data.description}
                  onChange={(e) =>
                    _setData({ ...data, description: e.target.value })
                  }
                  placeholder="Create a Mini Description less than 20 words"
                />
                {ImageLink === "" ? (
                  <>
                    {" "}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="image"
                      onChange={(event) => FilesUpload(event)}
                      name="img"
                      accept="image/*"
                    />
                    <button
                      onClick={() => {
                        document.getElementById("image").click();
                      }}
                      className="button is-link mt-3"
                    >
                      Upload Image
                    </button>
                  </>
                ) : (
                  <>
                    <img alt="" className="mt-3" src={ImageLink} />
                    <button
                      onClick={() => {
                        _setImageLink("");
                        _setFile(null);
                      }}
                      style={{ width: "100%" }}
                      className="button is-dark"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={Upload}
                      style={{ width: "100%" }}
                      className="button is-link mt-2 mb-6"
                    >
                      Submit
                    </button>
                  </>
                )}
                {loading && (
                  <progress className="progress is-small is-primary" max="100">
                    15%
                  </progress>
                )}
              </div>
              <div className="column is-12">
                <button
                  onClick={ToggleDrawer}
                  style={{ width: "100%" }}
                  className="button is-dark"
                >
                  Cancel
                </button>
              </div>
            </Container>
          </div>
        ) : (
          <div className="column is-6">
            <button onClick={ToggleDrawer} className="button is-link">
              Create Product
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default CreateProduct;

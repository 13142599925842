import firebase from 'firebase'

function validateDataSnapshot(snap) {
    if (snap && snap.exists()) {
        return snap
    }
    return null;
}

export const getB2BUsers = () => firebase
        .functions()
        .httpsCallable('getB2BUsers').call(null)


export const updateB2BUser = async (uid, data) => {
    await firebase.database().ref(`users/${uid}`).update(data);
}

import React from "react";
import styled from "styled-components";
import firebase from "firebase";
import axios from "axios";
import ProductCard from "./EditProducts/ProductCard";
import { url } from "./../config/api";
const Container = styled.div``;

const EditProducts = () => {
  const [categories, _setCategories] = React.useState([]);
  const [products, _setProducts] = React.useState(null);
  const [selectedCategory, _setSelectedCategory] = React.useState("");
  const [penddingOrders, _setPenddingOrders] = React.useState([]);
  const FetchCategory = () => {
    firebase
      .database()
      .ref(`categories`)
      .once("value", (snap) => {
        _setCategories([...Object.keys(snap.val())]);
      });
  };

  React.useEffect(() => {
    OrderPenddingFetch();
    FetchCategory();
  }, []);
  const OrderPenddingFetch = async () => {
    const token = await firebase.auth().currentUser.getIdToken();
    firebase
      .database()
      .ref(`order`)
      .once(`value`, (snap) => {
        const _penddingOrders = [];
        snap.forEach((item) => {
          let addStat = false;
          for(var element in item.val()) {
           if(item.val()[element].status === "processed")
            addStat = true;
          } 
          if(addStat){
            _penddingOrders.push({time:Math.random(),userid:item.key,orders:item.val()});
          }
          // console.log(_penddingOrders);
        });
        console.log(_penddingOrders);
        _setPenddingOrders(_penddingOrders);
      });
  };
  const FetchProducts = (item) => {
    firebase
      .database()
      .ref(`vegetables/${item}`)
      .once("value", (snap) => {
        let array = [];
        _setSelectedCategory(item);

        if (snap.val()) {
          snap.forEach((item) => {
            array.push({ ...item.val(), itemDatabase: item.key });
            _setProducts([...array]);
          });
        } else {
          _setProducts([]);
        }
      });
  };

  return (
    <>
      <div className="columns">
        <div className="column is-4">
          <div className="columns is-multiline is-gapless">
            <h3 className="title is-2">Categories</h3>
            {categories.map((item, index) => (
              <div key={index} className="column is-12 my-1">
                <button
                  onClick={() => {
                    FetchProducts(item);
                  }}
                  style={{ width: "100%" }}
                  className="button is-link"
                >
                  {item}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="column is-8">
          {Array.isArray(products) && products.length === 0 && (
            <h3 className="title is-4">No products in this category</h3>
          )}
          {Array.isArray(products) &&
            products.map((item, index) => (
              <ProductCard
                selectedCategory={selectedCategory}
                pendngOrder={penddingOrders}
                key={Math.random()}
                productCardData={item}
              />
            ))}
        </div>
      </div>
    </>
  );
};
export default EditProducts;

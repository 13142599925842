import React, { useState, useEffect } from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBDataTableV5 } from 'mdbreact';
import firebase from "firebase";
import defaultImage from '../../default.png'
import loadingImage from '../../loading.gif'
import Switch from "react-switch";
import { Link } from "react-router-dom";
import * as Constants from '../StateManager/Constants'
import { store } from 'react-notifications-component';
import imageCompression from "browser-image-compression";

const EditProduct = () => {
    const dbpathcat = "customerapp/categories";
    const dbpathUnits = "customerapp/units";
    const dbpathcoupons = "customerapp/coupons";
    const dbpathprod = "customerapp/products";//products/catid/productid
    const imagepathprod = "products/images";//products/images/productid/all_images
    const [categories, setCategories] = useState(Constants.selectedProduct.categories||[])
    const [productTitle, setProductTitle] = useState("")
    const [description, setDescription] = useState("")
    const [productImage, setProductImage] = useState([])
    const [isImageUpdated, setIsImageUpdated] = useState(false)
    const [productID, setProductID] = useState(Constants.selectedProduct.prodID||"")
    const [selectedCatId, setSelectedCatId] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [buttonName, setButtonName] = useState("Add")
    const [hideStatus, setHideStatus] = useState(false);
    const [coupons, setCoupons] = useState(Constants.selectedProduct.coupons||[])
    const [discountPackageID, setDiscountPackageID] = useState("")
    const [units, setUnits] = useState(Constants.selectedProduct.units||[])
    const [selectedUnit, setSelectedUnit] = useState("")
    const [maxQuantity, setMaxQuantity] = useState("")
    const [minQuantity, setMinQuantity] = useState("")
    const [productPrice, setProductPrice] = useState("")
    const [stockSize, setStockSize] = useState("0")
    const [defaultImageIndex, setDefaultImageIndex] = useState(0)
    const [isImageLoading, setisImageLoading] = useState(false)
    const discountTypeArr = ["precentage", "flat"];
    const [showPrice, setShowPrice] = useState("")
    // const [imagePosInList, setImagePosInList] = useState(0)
    const fetchCategories = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcat)
            .once(`value`, (snap) => {
                const data = snap.val();
                console.log(data);
                if (data)
                    setCategories(data)
            });
    }
    const fetchUnits = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathUnits)
            .once(`value`, (snap) => {
                const data = snap.val();
                // console.log(data);
                if (data)
                    setUnits(data)
            });
    }
    const fetchCoupons = async () => {
        const token = await firebase.auth().currentUser.getIdToken();
        firebase
            .database()
            .ref(dbpathcoupons)
            .once(`value`, (snap) => {
                const data = snap.val();
                console.log(data);
                if (data)
                    setCoupons(data)
            });
    }
    
    const getCategory = (catid) => {
        return categories[catid];
    };
    const getUnit = (unitid) => {
        return units[unitid].title;
    };
    const getCurrentPrice = (priceObj = []) => {
        let lastObj = {};
        priceObj.forEach(element => {
            lastObj = element
        });
        lastObj.unitTitle = getUnit(lastObj.unitid);
        return lastObj;
    };
    const setValuesToForm = (Product) => {
        console.log(Product);
        let lastPrice = getCurrentPrice(Product.price)
        let prodImages = Product.images || [];
        prodImages.forEach((element, indx) => {
            if (element.isDefault)
                setDefaultImageIndex(indx)
        });
        setProductID(Constants.selectedProduct.prodID||"")
        setIsProcessing(false)
        setIsImageUpdated(false)
        setProductImage(Product.images || []);
        setProductTitle(Product.title);
        setProductPrice(lastPrice.amount)
        setShowPrice(lastPrice.showPrice||"")
        setSelectedUnit(lastPrice.unitid||"")
        setDiscountPackageID(Product.couponid)
        setSelectedCatId(Product.catid)
        setHideStatus(Product.hide)
        setMaxQuantity(Product.maxQuantity)
        setMinQuantity(Product.minQuantity);
        setStockSize(Product.stockSize)
        setDescription(Product.description||"")
        setButtonName("Update");
        window.scrollTo(0, 0)
    }
    const showMessage = (message, type = "success") => {
        store.addNotification({
            title: "Notification",
            message: message,
            type: type,
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    }
    const updateProduct = async () => {
        if (selectedCatId.trim().length === 0) {
            showMessage("Please select category first", "danger");
            return;
        }
        if (productTitle.trim().length === 0) {
            showMessage("Please enter product title", "danger");
            return;
        }
        if (productImage.length === 0) {
            showMessage("Please select product image", "danger");
            return;
        }
        if (productPrice.toString().trim().length === 0) {
            showMessage("Please enter product price", "danger");
            return;
        }
        if (selectedUnit.toString().trim().length === 0) {
            showMessage("Select quantity unit", "danger");
            return;
        }
        if (maxQuantity.toString().trim().length === 0) {
            showMessage("Please set maximum selling quantity", "danger");
            return;
        }
        if (minQuantity.toString().trim().length === 0) {
            showMessage("Please set minimum selling quantity", "danger");
            return;
        }
        if (stockSize.toString().trim().length === 0) {
            showMessage("Please set current stock size", "danger");
            return;
        }
        if (stockSize.toString().trim() === "0") {
            var contioue = window.confirm("Stock Size is zero,Press OK to proceed");
            if (!contioue) {
                return;
            }
        }
        setIsProcessing(true);
        saveToDb();
    }
    const FilesUpload = async (event) => {
        let pos =  Constants.selectedImgIndex.pos;
        console.log(pos);
        const imageFile = event.target.files[0];
        var imageObj = [];
        setisImageLoading(true)

        console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        let compressedFile = null;
        try {
            compressedFile = await imageCompression(imageFile, options);
        } catch (error) {
            console.log(error);
        }
        if (compressedFile != null) {
            let unique = Math.random().toString().substring(4, 9);
            let imgIndx = (productImage.length + 1).toString() + unique;
            if (pos != -1) {
                imgIndx = pos;
            }
            let filePath = `${imagepathprod}/${productID}/${imgIndx}`;
            await firebase
                .storage()
                .ref(filePath)
                .put(compressedFile)
                .then(() => {
                    firebase
                        .storage()
                        .ref(filePath)
                        .getDownloadURL()
                        .then((url) => {
                            let imgObjc = productImage;
                            if (pos != -1) {
                                imgObjc[pos].url = url;
                            } else {
                                imgObjc.push({ url: url, isDefault: false })
                            }
                            setProductImage([...imgObjc]);
                            updateImageDatabase(imgObjc);
                        })
                        .catch((e) => console.log(e));
                })
                .catch((e) => console.log(e));
        }
        setisImageLoading(false)
    };
    const updateImageDatabase = (imgObjc) => {
        console.log(imgObjc);
        firebase
            .database()
            .ref(`${dbpathprod}/${selectedCatId}/${productID}/images`)
            .set(imgObjc)
            .then(() => {
                showMessage("Changes saved")
            });
    }
    const saveToDb = () => {
        var dt = new Date();
        let datestr = dt.getTime();
        var priceobjs = Constants.selectedProduct.prod.price||[];
        
        
        if (getCurrentPrice(priceobjs).amount != productPrice) {
            priceobjs.push({ date: datestr, amount: productPrice, unitid: selectedUnit ,showPrice:showPrice })
        }
        console.log(getCurrentPrice(priceobjs).amount,productPrice,selectedUnit);
        if (getCurrentPrice(priceobjs).amount == productPrice &&
            (getCurrentPrice(priceobjs).unitid != selectedUnit ||
            getCurrentPrice(priceobjs).showPrice != showPrice )) {
            let lastIndex = priceobjs.length - 1;
            priceobjs[lastIndex].unitid = selectedUnit;
            priceobjs[lastIndex].showPrice = showPrice;
        }
        var priceobj2=[];
        priceobjs.forEach(element => {
            priceobj2.push({ date: element.date, amount: element.amount, unitid: element.unitid, showPrice:element.showPrice })
        });
        let obj = {
            prodid:productID,
            catid: selectedCatId,
            title: productTitle,
            price: priceobj2,
            maxQuantity,
            minQuantity,
            stockSize,
            hide: hideStatus,
            couponid: discountPackageID,
            deleteable: true,
            description
        }
        // console.log(obj);        
        firebase
            .database()
            .ref(`${dbpathprod}/${selectedCatId}/${productID}`)
            .update(obj)
            .then(() => {
                showMessage("Changes saved")
                setTimeout(() => {
                    window.history.back();
                }, 2000);
            });

    }
    const filterFloat = (amt) => {
        var val = amt;
        var subst = '';
        var regex = /[^\d.]|\.(?=.*\.)/g;
        val = val.replace(regex, subst);
        return val;
    }
    useEffect(() => {
        // console.log(Constants.selectedProduct);
        // fetchCategories()
        // fetchUnits()
        // fetchCoupons()
    }, [])
    useEffect(() => {
        console.log("categories,units loaded");
        // console.log(Object.keys(categories).length, Object.keys(units).length);
        if (Object.keys(categories).length > 0 && Object.keys(units).length > 0) {
            setValuesToForm(Constants.selectedProduct.prod||{})
        }
    }, [categories, units])

    return (
        <div>
            <h2>&nbsp;Edit Product</h2>

            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <form onSubmit={e => e.preventDefault()}>
                            <div className='m-1 pt-5'>
                                <label htmlFor='selectboxid'>Select category</label>
                                <select id='selectboxid' className='form-control' value={selectedCatId} onChange={(e) => { setSelectedCatId(e.target.value); }} required>
                                    <option value="">Select Category</option>
                                    {
                                        Object.keys(categories).map(key => {
                                            return (
                                                <option key={key} value={key}>{categories[key].title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='m-1'>
                                <label>Product Title</label>
                                <input required className='form-control' type={"text"} placeholder='Product Title' value={productTitle} onChange={(e) => setProductTitle(e.target.value)} />
                            </div>
                            <div className='m-1'>
                                <label>Product Description</label>
                                <textarea style={{ resize: "none" }} className='form-control' type={"text"} placeholder='Product Description' value={description} onChange={(e) => setDescription(e.target.value)} ></textarea>
                            </div>
                            <div className='m-1'>
                                <label htmlFor="prodimage">Set Product Image(Click Here)</label>
                                <button type='button' onClick={() => {
                                     Constants.selectedImgIndex.pos=-1;
                                    document.getElementById("prodimage").click();
                                }} className='btn btn-info  btn-sm'>Add</button>
                                <input className='d-none' accept='image/*' id={"prodimage"} type={"file"} onChange={(event) => FilesUpload(event)} />
                                <br />
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {isImageLoading && (
                                        <div className='image_container'>
                                            <img alt="" style={{ width: 100, height: 100 }} className="mt-3" src={loadingImage} />
                                        </div>
                                    )}

                                    {
                                        productImage.map((element, index) => {
                                            return (
                                                <div key={index} className='image_container'>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            var imgArr = productImage;
                                                            for(let i=0;i<imgArr.length;i++){
                                                                imgArr[i].isDefault = false;
                                                            }
                                                            imgArr[index].isDefault = true;
                                                            setDefaultImageIndex(index)
                                                            setProductImage([...imgArr])
                                                            updateImageDatabase(imgArr);
                                                        }}
                                                        className={'btn btn-rounded btn-sm image_container_button ' + (index === defaultImageIndex ? "btn-success" : "btn-dark")} style={{ left: 0 }}>Default</button>
                                                    <button
                                                        type='button'
                                                        onClick={() => {
                                                            var imgArr = productImage;
                                                            var storage = firebase.storage();
                                                            let imageRef = storage.refFromURL(imgArr[index].url);
                                                            imageRef.delete()
                                                            imgArr.splice(index, 1);
                                                            if (defaultImageIndex == index) {
                                                                setDefaultImageIndex(0);
                                                            }
                                                            if(imgArr.length>0){
                                                                imgArr[0].isDefault = true;
                                                            }
                                                            setProductImage([...imgArr])
                                                            updateImageDatabase(imgArr);
                                                        }}
                                                        className='btn btn-danger btn-rounded btn-sm image_container_button' style={{ right: 0 }}>Delete</button>
                                                    <img onClick={() => {
                                                        Constants.selectedImgIndex.pos=index;
                                                        document.getElementById("prodimage").click();
                                                    }} alt="" title="Click to replace" style={{ width: 200, height: 100, cursor:"pointer" }} className="mt-3" src={element.url || defaultImage} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className='m-1'>
                                <label>Product Price(Actual)</label>
                                <input required className='form-control' type={"text"} placeholder='Product Price' value={productPrice} onChange={(e) => setProductPrice(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Product Show Price</label>
                                <input className='form-control' type={"text"} placeholder='Product Price' value={showPrice} onChange={(e) => setShowPrice(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label htmlFor='selectboxid2'>Set Quantity Unit</label>
                                <select value={selectedUnit} id='selectboxid2' className='form-control' onChange={(e) => { setSelectedUnit(e.target.value); }} required>
                                    <option value="">Set Quantity Unit</option>
                                    {
                                        Object.keys(units).map(key => {
                                            return (
                                                <option key={key} value={key}>{units[key].title}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='m-1'>
                                <label>Max Selling Quantity</label>
                                <input required className='form-control' type={"text"} placeholder='Max Selling Quantity' value={maxQuantity} onChange={(e) => setMaxQuantity(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Min Selling Quantity</label>
                                <input required className='form-control' type={"text"} placeholder='Min Selling Quantity' value={minQuantity} onChange={(e) => setMinQuantity(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <label>Stock Size</label>
                                <input required className='form-control' type={"text"} placeholder='Current Stock Size' value={stockSize} onChange={(e) => setStockSize(filterFloat(e.target.value))} />
                            </div>
                            <div className='m-1'>
                                <div>
                                    <label>Make Hide?</label><br />
                                    <Switch onChange={() => { setHideStatus(!hideStatus) }}
                                        checked={hideStatus}
                                    />
                                </div>
                            </div>
                            <div className='m-1'>
                                <label htmlFor='selectboxid3'>Add Discount/Coupon (Optional) </label>
                                <select id='selectboxid3' className='form-control' value={discountPackageID} onChange={(e) => { setDiscountPackageID(e.target.value); }}>
                                    <option value="">Not Applied</option>
                                    {
                                        Object.keys(coupons).map(key => {
                                            return (
                                                <option key={key} value={key}>{coupons[key].title} ({coupons[key].amount}{" "}{coupons[key].type === discountTypeArr[0] ? "%" : "/- Rs"})</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            <div className='mb-5 pb-5'>
                                <button disabled={isProcessing} type='submit' onClick={() => { updateProduct() }} className='btn btn-info  btn-sm'>{buttonName}</button>
                                <button disabled={isProcessing} type='button' onClick={() => { setValuesToForm(Constants.selectedProduct.prod||{}) }} className='btn btn-danger  btn-sm'>Reset</button>
                            </div>
                            <div className='mb-5'></div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default EditProduct

import React from "react";
import styled from "styled-components";

import CreateProduct from "./ShowCategoryAndCreateProduct";

const Container = styled.div``;

const UploadToCategory = ({ categories }) => {
  return (
    <Container>
      <h3 className="title is-4 has-text-weight-normal">Upload To Category</h3>
      {categories.map((item) => (
        <CreateProduct category={item.name} {...item} />
      ))}
    </Container>
  );
};
export default UploadToCategory;

/* eslint-disable jsx-a11y/no-access-key */
import React from "react";
import styled from "styled-components";
import firebase from "firebase";

import ShowProductsOrdered from "./ShowProductsOrdered";

const Container = styled.div``;

const OrderCard = ({ phone, orderData, filter, OrderFetch, shopName, DeliveryBoy }) => {
    console.log(orderData)
  const [orderShown, _setOrderShown] = React.useState(false);
  const ChangeStatus = (status, key) => {
    firebase
      .database()
      .ref(`order/${phone}/${key}`)
      .update({ status })
      .then(() => {
        OrderFetch();
      });
  };

  const addDeliveryBoy = (delivertBoyID,key)=>{
    if(delivertBoyID.toString().trim().length>0){
       console.log(`order/${phone}/${key}`);
       console.log("delivery boy "+delivertBoyID);
      firebase.database()
                .ref(`order/${phone}/${key}`)
                .update({"deliveryBoyIDs":delivertBoyID})
                .then(() => {
                    console.log("Updated");
                    alert("Delevery boy assigned")
                    OrderFetch();
                });
    }else{
        alert("Please select one delevery boy to assign")
    }
}
  const [orderDataState, _setOrderDataState] = React.useState({});
  const ToggleOrderShown = () => _setOrderShown(!orderShown);

  React.useEffect(() => {
    _setOrderDataState({ ...orderData });
  }, [orderData]);

  return (
    <Container className="px-4">
      <div className="columns is-multiline mt-2">
        {Object.keys(orderDataState).map((key, index) => (
          <ShowProductsOrdered
            phone={phone}
            shopName={orderData[key].shopName}
            OrderFetch={OrderFetch}
            filter={filter}
            orderData={orderData}
            accessKey={key}
            DeliveryBoy={DeliveryBoy}
            ChangeStatus={ChangeStatus}
            addDeliveryBoy = {addDeliveryBoy}
            key={index}
          />
        ))}
      </div>
    </Container>
  );
};
export default OrderCard;

import React, {useEffect, useState} from 'react'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {MDBDataTableV5} from 'mdbreact';
import firebase from "firebase";
import Modal from "react-modal";
import {store} from 'react-notifications-component';

const modal_root = document.getElementById("modal_root");
Modal.setAppElement(modal_root);
const DeliveryBoys = () => {
    const dbpathcat = "customerapp/categories";
    const dbpathUnits = "customerapp/units";
    const dbpathOrders = "customerapp/orders";
    const dbpathUsers = "customerapp/users";
    const dbpathDeliveryBoy = "customerapp/delivery/users";
    const [updated, setUpdate] = useState("")
    const [deliveryBoyOrders, setDeliveryBoyOrders] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(0);

    async function setOrders() {
        const orders = [];
        const snap = await firebase.database().ref('order').once("value");
        const orderB2b = snap.val();
        Object.keys(orderB2b).forEach(ph => {
            Object.keys(orderB2b[ph]).forEach(o => {
                if (orderB2b[ph][o].deliveryBoyIDs) {
                    if (typeof orderB2b[ph][o].deliveryBoyIDs === "string") {
                        if (orderB2b[ph][o].deliveryBoyIDs === modalOpen.userid) orders.push({
                            ...orderB2b[ph][o],
                            type: "b2b",
                            orderid: o
                        });
                    }
                    if (typeof orderB2b[ph][o].deliveryBoyIDs === "object") {
                        Object.keys(orderB2b[ph][o].deliveryBoyIDs).forEach(d => {
                            if (orderD2c[ph][o].deliveryBoyIDs[d].userid && orderD2c[ph][o].deliveryBoyIDs[d].userid === modalOpen.userid) orders.push({
                                ...orderB2b[ph][o],
                                type: "b2b",
                                orderid: o
                            })
                        })
                    }
                }
            })
        })
        const snap2 = await firebase.database().ref(dbpathOrders).once("value");
        const orderD2c = snap2.val();
        Object.keys(orderD2c).forEach(ph => {
            console.log(ph)
            Object.keys(orderD2c[ph]).forEach(o => {
                if (orderD2c[ph][o].deliveryBoyIDs) {
                    if (typeof orderD2c[ph][o].deliveryBoyIDs === "string") {
                        if (orderD2c[ph][o].deliveryBoyIDs === modalOpen.userid) orders.push({
                            ...orderD2c[ph][o],
                            type: "b2b",
                            orderid: o
                        });
                    }
                    if (typeof orderD2c[ph][o].deliveryBoyIDs === "object") {
                        Object.keys(orderD2c[ph][o].deliveryBoyIDs).forEach(d => {
                            console.log(d, modalOpen.userid)
                            if (orderD2c[ph][o].deliveryBoyIDs[d].userid && orderD2c[ph][o].deliveryBoyIDs[d].userid === modalOpen.userid) orders.push({
                                ...orderD2c[ph][o],
                                type: "b2b",
                                orderid: o
                            })
                        })
                    }
                }
            })
        })
        setDeliveryBoyOrders(orders)
    }

    useEffect(() => {
        if (modalOpen) {
            setOrders()

        }
    }, [modalOpen])
    const [deliveryBoysDatatable, setdeliveryBoysDatatable] = useState({
        columns: [{
            label: 'Name', field: 'name', attributes: {
                'aria-controls': 'DataTable', 'aria-label': 'Category',
            },
        }, {
            label: 'Completed Orders', field: 'completedOrders', attributes: {
                'aria-controls': 'DataTable', 'aria-label': 'Description',
            },
        }, {
            label: 'Available Balance', field: 'availableBalance', attributes: {
                'aria-controls': 'DataTable', 'aria-label': 'Description',
            },
        }, {
            label: 'Collection', field: 'collection', attributes: {
                'aria-controls': 'DataTable', 'aria-label': 'Description',
            },
        }, {
            label: 'Action', field: 'action', attributes: {
                'aria-controls': 'DataTable', 'aria-label': 'Action',
            },
        },], rows: []
    });
    useEffect(() => {
        fetchUsers()
    }, [])

    function updateUser() {
        console.log(modalOpen)
        if (modalOpen)
            firebase.database()
                .ref(`${dbpathDeliveryBoy}/${modalOpen.uniqueuserid}/`)
                .update(modalOpen)
                .then(() => {
                    console.log("Updated");

                    deliveryBoysDatatable.rows[modalOpen.pos] = getRow(modalOpen, modalOpen.pos, modalOpen.uniqueuserid);
                    console.log("deliveryBoysDatatable[pos] ", deliveryBoysDatatable[modalOpen.pos]);
                    setdeliveryBoysDatatable({...deliveryBoysDatatable});
                    setUpdate(Math.random())
                    store.addNotification({
                        title: "Notification",
                        message: "Status Updated",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 3000, onScreen: true,
                        },
                    });
                });

    }

    const blockUnblockUser = (userDetails, pos, uniqueuserid, blockstatus) => {
        firebase.database()
            .ref(`${dbpathDeliveryBoy}/${uniqueuserid}/`)
            .update({isBlocked: !blockstatus})
            .then(() => {
                console.log("Updated");

                userDetails.isBlocked = !blockstatus;
                deliveryBoysDatatable.rows[pos] = getRow(userDetails, pos, uniqueuserid);
                console.log("deliveryBoysDatatable[pos] ", deliveryBoysDatatable[pos]);
                setdeliveryBoysDatatable({...deliveryBoysDatatable});
                setUpdate(Math.random())
                store.addNotification({
                    title: "Notification",
                    message: "Status Updated",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000, onScreen: true,
                    },
                });
            });
    }
    const approveDisapproveUser = (userDetails, pos, uniqueuserid, approvestatus) => {
        firebase.database()
            .ref(`${dbpathDeliveryBoy}/${uniqueuserid}/`)
            .update({isApproved: !approvestatus})
            .then(() => {
                console.log("Updated");

                userDetails.isApproved = !approvestatus;
                deliveryBoysDatatable.rows[pos] = getRow(userDetails, pos, uniqueuserid);
                console.log("deliveryBoysDatatable[pos] ", deliveryBoysDatatable[pos]);
                setdeliveryBoysDatatable({...deliveryBoysDatatable});
                setUpdate(Math.random())
                store.addNotification({
                    title: "Notification",
                    message: "Approval Status Updated",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000, onScreen: true,
                    },
                });
            });
    }
    const rejectUser = (userDetails, pos, uniqueuserid) => {
        firebase.database()
            .ref(`${dbpathDeliveryBoy}/${uniqueuserid}/`)
            .update({isRejected: true})
            .then(() => {
                console.log("Updated");

                userDetails.isRejected = true;
                deliveryBoysDatatable.rows[pos] = getRow(userDetails, pos, uniqueuserid);
                console.log("deliveryBoysDatatable[pos] ", deliveryBoysDatatable[pos]);
                setdeliveryBoysDatatable({...deliveryBoysDatatable});
                setUpdate(Math.random())
                store.addNotification({
                    title: "Notification",
                    message: "User Rejected",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000, onScreen: true,
                    },
                });
            });
    }
    const collectUserMoney = (userDetails, pos, uniqueuserid) => {
        let money = {}
        if (userDetails.money) money = {...userDetails.money};
        Object.keys(userDetails.money).forEach(m => {
            if (money[m].collected) money[m].submitted = money[m].collected;
        })
        firebase.database()
            .ref(`${dbpathDeliveryBoy}/${uniqueuserid}/`)
            .update({money})
            .then(() => {
                console.log("Updated");

                userDetails.money = money;
                deliveryBoysDatatable.rows[pos] = getRow(userDetails, pos, uniqueuserid);
                console.log("deliveryBoysDatatable[pos] ", deliveryBoysDatatable[pos]);
                setdeliveryBoysDatatable({...deliveryBoysDatatable});
                setUpdate(Math.random())
                store.addNotification({
                    title: "Notification",
                    message: "Money Collected",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000, onScreen: true,
                    },
                });
            });
    }
    const getRow = (userDetails, oindex, uniqueuserid) => {
        let availableBalance = 0, completedOrders = 0;

        if (userDetails.money) {
            Object.keys(userDetails.money).forEach(m => {
                completedOrders += 1;
                if (userDetails.money[m].collected) availableBalance += userDetails.money[m].collected;
                if (userDetails.money[m].submitted) availableBalance -= userDetails.money[m].submitted;
            })
        }
        let obj = {
            name: <span onClick={() => {
                setModalOpen({...userDetails, uniqueuserid, pos: oindex})
                setModalType(0)
            }}>{userDetails.name}</span>,
            completedOrders: <button className="btn btn-rounded btn-sm  btn-success" onClick={() => {
                setModalOpen({...userDetails, uniqueuserid, pos: oindex})
                setModalType(1)
            }}>Orders</button>,
            availableBalance: "Rs." + availableBalance,
            collection: (
                <button
                    key={Math.random()}
                    type="button"
                    onClick={() => collectUserMoney(userDetails, oindex, uniqueuserid)}
                    className={"btn btn-rounded btn-sm  btn-success"}>
                    Collect
                </button>
            ),
            action: (
                <span>
                    {userDetails.isApproved && <button
                        key={Math.random()}
                        type="button"
                        onClick={() => blockUnblockUser(userDetails, oindex, uniqueuserid, userDetails.isBlocked)}
                        className={
                            userDetails.isBlocked ?
                                "btn btn-rounded btn-sm  btn-warning" : "" +
                                "btn btn-rounded btn-sm  btn-danger"
                        }>
                        {!userDetails.isBlocked ? "Block" : "Unblock"}
                    </button>}
                    {!userDetails.isApproved && <button
                        key={Math.random()}
                        type="button"
                        onClick={() => approveDisapproveUser(userDetails, oindex, uniqueuserid, false)}
                        className={
                            userDetails.isApproved ?
                                "btn btn-rounded btn-sm  btn-danger" : "" +
                                "btn btn-rounded btn-sm  btn-success"
                        }>
                        Approve
                    </button>}
                    {!userDetails.isApproved && <button
                        key={Math.random()}
                        type="button"
                        disabled={userDetails.isRejected}
                        onClick={() => rejectUser(userDetails, oindex, uniqueuserid)}
                        className={"btn btn-rounded btn-sm  btn-danger"}>
                        {userDetails.isRejected ? "Rejected" : "Reject"}
                    </button>}
                </span>
            ),
        };
        console.log(obj, 12)
        return obj;
    }
    const fetchUsers = () => {
        firebase.database()
            .ref(dbpathDeliveryBoy)
            .once('value')
            .then(snapshot => {
                let data = snapshot.val();
                if (data) {
                    let dbs = [];
                    let indx = 0;
                    Object.keys(data).map((dbid) => {
                        // if(data[dbid].isProfileComplete && !data[dbid].isBlocked)
                        dbs.push(getRow(data[dbid], indx, dbid))
                        indx++;
                    });
                    deliveryBoysDatatable.rows = dbs;
                    setdeliveryBoysDatatable({...deliveryBoysDatatable});

                }
            });
    }
    console.log(deliveryBoysDatatable)
    return <>
        <Modal isOpen={modalOpen} onRequestClose={() => {
            setModalOpen(false)
            setDeliveryBoyOrders(undefined);
        }}>
            {modalType === 0 && <div>
                <div className="w-100 d-flex justify-content-end">
                    <button onClick={()=>setModalOpen(false)} className="btn btn-danger btn-sm">Close</button>
                </div>
                <div>
                    <h2>Phone: {modalOpen.phone}</h2>
                </div>
                <div className="form-group">

                    <div className="form-label">Name</div>
                    <input className="form-control" value={modalOpen.name}
                           onChange={e => setModalOpen({...modalOpen, name: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Address Line 1</div>
                    <input className="form-control" value={modalOpen.address}
                           onChange={e => setModalOpen({...modalOpen, address: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Address Line 2</div>
                    <input className="form-control" value={modalOpen.address2}
                           onChange={e => setModalOpen({...modalOpen, address2: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">State</div>
                    <input className="form-control" value={modalOpen.state}
                           onChange={e => setModalOpen({...modalOpen, state: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Landmark</div>
                    <input className="form-control" value={modalOpen.landmark}
                           onChange={e => setModalOpen({...modalOpen, landmark: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Block No</div>
                    <input className="form-control" value={modalOpen.blockNo}
                           onChange={e => setModalOpen({...modalOpen, blockNo: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">User ID</div>
                    <input className="form-control" value={modalOpen.userid}
                           onChange={e => setModalOpen({...modalOpen, userid: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Pincode</div>
                    <input className="form-control" value={modalOpen.pincode}
                           onChange={e => setModalOpen({...modalOpen, pincode: e.target.value})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Money Withdrawn</div>
                    <input type={'number'} className="form-control" value={modalOpen.moneyWithdrawn}
                           onChange={e => setModalOpen({...modalOpen, moneyWithdrawn: parseInt(e.target.value)})}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Profile Completed?</div>
                    <b>{modalOpen.isProfileComplete ? "Completed" : "Not Completed"}</b>
                </div>
                <div className="form-group">
                    <div className="form-label">Vehicle Type</div>
                    <b>{modalOpen.vehicleType}</b>
                </div>

                <div className="form-group">
                    <div className="form-label">Selfie</div>
                    <img width={400} src={modalOpen.selfieImage}/>
                </div>
                <div className="form-group">
                    <div className="form-label">RC</div>
                    <img width={400} src={modalOpen.rcImage}/>
                </div>
                <div className="form-group">
                    <div className="form-label">Insurance</div>
                    <img width={400} src={modalOpen.insuranceImage}/>
                </div>
                <button className="btn btn-success" onClick={updateUser}>Update</button>
            </div>}
            {modalType === 1 && <div>
                <div className="w-100 d-flex justify-content-end">
                    <button onClick={()=>setModalOpen(false)} className="btn btn-danger btn-sm">Close</button>
                </div>
                <h3 className="m2">Completed Orders:</h3>

                {!deliveryBoyOrders && <h3>Loading...</h3>}
                {
                    deliveryBoyOrders && deliveryBoyOrders.map(k => <div className="m-3 border-1 border-dark">
                        <div>Order ID: {k.orderid}</div>
                        <div>Date: {k.date}</div>
                        <div>Price: {k.price}</div>
                        <div>Status: {k.status}</div>
                        {modalOpen.money && modalOpen.money[k.orderid] && <div>Money
                            Collected: {modalOpen.money[k.orderid].collected ? modalOpen.money[k.orderid].collected : 0}</div>}
                        {modalOpen.money && modalOpen.money[k.orderid] && <div>Money
                            Submitted: {modalOpen.money[k.orderid].submitted ? modalOpen.money[k.orderid].submitted : 0}</div>}
                        {modalOpen.money && modalOpen.money[k.orderid] && <div>Money
                            Earned: {modalOpen.money[k.orderid].earned ? modalOpen.money[k.orderid].earned : 0}</div>}
                        {modalOpen.money && modalOpen.money[k.orderid] && <div>Money
                            Withdrawn: {modalOpen.money[k.orderid].withdrawn ? modalOpen.withdrawn[k.orderid].collected : 0}</div>}
                    <hr/>
                    </div>)
                }</div>}
        </Modal>
        <MDBDataTableV5
            scrollX hover entriesOptions={[5, 20, 25, 50, 100]}
            entries={20} pagesAmount={4} data={deliveryBoysDatatable}/>
    </>;
}

export default DeliveryBoys;
